<template>
	<div class="aside">
		Aside
	</div>
</template>

<script>
export default{
	name:"Aside"
}
</script>

<style>
</style>