import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '@/assets/global.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
// import * as echarts from 'echarts'
import { ElMessage } from 'element-plus'
import global from './components/global.vue'

// import videojs from "video.js";
// import "video.js/dist/video-js.css";


const app =createApp(App)
app.use(store)
app.use(router)
app.use(ElementPlus,{
    locale: zhCn
})
// app.config.globalProperties.$echarts = echarts
// 全局变量
app.config.globalProperties.$GLOBAL = global
// app.config.globalProperties.$video = videojs
app.mount('#app')

// router.beforeEach((to,from,next) => {
// 	let is_Phone = false
// 	let screenWidth = document.documentElement.clientWidth
// 	if(screenWidth<1400){
// 		is_Phone= true
// 	}
// 	if(!is_Phone){
// 		if(to.fullPath=="/loading"){
// 			next("/main")
// 		}else{
// 			next()
// 		}
// 	}else{
// 		if(to.fullPath=="/loading"){
// 			next()
// 		}else{
// 			next("/loading")
// 			console.log("手机版本正在开发中...")
// 		}
// 	}
// 	console.log(to.fullPath)
// 	// console.log(from)
	
// })



// createApp(App).use(store).use(router).mount('#app')
