<template>
	<Header></Header>
	Login
</template>

<script>
import Header from '@/components/Header'
export default{
	name:"Login",
	components:{
		Header
	}
}
</script>

<style>
</style>