<template>
	<div class="phone_product_list_layout">
		<div class="phone_product_list_menu">
			<div class="phone_product_list_menu_layout">
				<div class="phone_product_menu_item" style="border-right: 1px solid #55ffff;">
					<div :class="show_product_list_index=='1'? 'phone_product_menu_item_main choose_phone_product_menu_item':'phone_product_menu_item_main' " @click="change_show_product_list_index(1)" >
						控制器
					</div>
					<div :class="show_product_list_index_sub=='1_1'? 'phone_product_menu_item_sub choose_phone_product_menu_item':'phone_product_menu_item_sub' " @click="change_show_product_list_sub_index('1_1')">
						机器人控制器
					</div>
					<div :class="show_product_list_index_sub=='1_2'? 'phone_product_menu_item_sub choose_phone_product_menu_item':'phone_product_menu_item_sub' " @click="change_show_product_list_sub_index('1_2')">
						AMR/AGV控制器
					</div>
					<div :class="show_product_list_index_sub=='1_3'? 'phone_product_menu_item_sub choose_phone_product_menu_item':'phone_product_menu_item_sub' " @click="change_show_product_list_sub_index('1_3')">
						PLC控制器
					</div>
					<div :class="show_product_list_index_sub=='1_4'? 'phone_product_menu_item_sub choose_phone_product_menu_item':'phone_product_menu_item_sub' " @click="change_show_product_list_sub_index('1_4')">
						视觉控制器
					</div>
					
				</div>
				<div class="phone_product_menu_item" style="border-left: 1px solid #55ffff;">
					<div :class="show_product_list_index=='2'? 'phone_product_menu_item_main choose_phone_product_menu_item':'phone_product_menu_item_main' " @click="change_show_product_list_index(2)" >
						集成软件
					</div>
					<div :class="show_product_list_index_sub=='2_1'? 'phone_product_menu_item_sub choose_phone_product_menu_item':'phone_product_menu_item_sub' " @click="change_show_product_list_sub_index('2_1')">
						机器人离线编程软件
					</div>
					<div :class="show_product_list_index_sub=='2_2'? 'phone_product_menu_item_sub choose_phone_product_menu_item':'phone_product_menu_item_sub' " @click="change_show_product_list_sub_index('2_2')">
						移动小车远程控制软件
					</div>
					<div :class="show_product_list_index_sub=='2_3'? 'phone_product_menu_item_sub choose_phone_product_menu_item':'phone_product_menu_item_sub' " @click="change_show_product_list_sub_index('2_3')">
						AMR/AGV调度软件
					</div>
					<div :class="show_product_list_index_sub=='2_4'? 'phone_product_menu_item_sub choose_phone_product_menu_item':'phone_product_menu_item_sub' " @click="change_show_product_list_sub_index('2_4')">
						数字孪生可视化平台
					</div>
					<div :class="show_product_list_index_sub=='2_5'? 'phone_product_menu_item_sub choose_phone_product_menu_item':'phone_product_menu_item_sub' " @click="change_show_product_list_sub_index('2_5')">
						5G云平台
					</div>
					<div :class="show_product_list_index_sub=='2_6'? 'phone_product_menu_item_sub choose_phone_product_menu_item':'phone_product_menu_item_sub' " @click="change_show_product_list_sub_index('2_6')">
						工业视觉软件
					</div>
					
				</div>
				<div :class="show_product_list_index=='3'? 'phone_product_menu_item_main choose_phone_product_menu_item':'phone_product_menu_item_main' " style="width: 100%;height: 30px;line-height: 30px;float: left;" @click="change_show_product_list_index(3)">
					特种设备定制
				</div>
			</div>
			<div class="product_list_menu_title" >
				<div style="font-size: 22px;font-weight: 600;line-height: 38px;">
					产品介绍
				</div>
				<div style="margin-top: -15px;font-size: 15px;line-height: 25px;">
					Product Introduction
				</div>
				<div class="product_introduction_btn" v-if="false">
					控制器/继承软件
				</div>
			</div>
		</div>
		<div class="phone_product_list_content">
			<div class="one_product_list" v-show="show_product_list_index=='1'&&!show_product_detail">
				<div class="phone_one_product_layout" @click="change_show_product_list_sub_index('1_1')" >
					<div class="one_product_img one_product_img_1" style="width: 100%;height: calc(100% - 40px);margin-top: 10px;">
						
					</div>
					<div class="one_product_text">
						机器人控制器
					</div>
				</div>
				<div class="phone_one_product_layout " @click="change_show_product_list_sub_index('1_2')" style="margin-left: 10px;">
					<div class="one_product_img one_product_img_2" style="width: 100%;height: calc(100% - 40px);margin-top: 10px;">
						
					</div>
					<div class="one_product_text">
						AMR/AGV控制器
					</div>
				</div>
				<div class="phone_one_product_layout " @click="change_show_product_list_sub_index('1_3')" >
					<div class="one_product_img one_product_img_4" style="width: 100%;height: calc(100% - 40px);margin-top: 10px;">
						
					</div>
					<div class="one_product_text">
						PLC控制器
					</div>
				</div>
				<div class="phone_one_product_layout " @click="change_show_product_list_sub_index('1_4')" style="margin-left: 10px;">
					<div class="one_product_img one_product_img_18" style="width: calc(100% - 50px);height: calc(100% - 70px);margin-top: 20px;margin-left: 25px;">
						
					</div>
					<div class="one_product_text">
						视觉控制器
					</div>
				</div>
				
				
			</div>
			<div class="one_product_list" v-show="show_product_list_index=='2'&&!show_product_detail">
				<div class="phone_one_product_layout"  @click="change_show_product_list_sub_index('2_1')">
					<div class="one_product_img one_product_img_5" style="width: calc(100% - 20px);height: calc(100% - 55px);margin-top: 10px;margin-left: 10px;">
						
					</div>
					<div class="one_product_text">
						机器人离线编程软件
					</div>
				</div>
				<div class="phone_one_product_layout"  @click="change_show_product_list_sub_index('2_2')" style="margin-left: 10px;">
					<div class="one_product_img one_product_img_7" style="width: calc(100% - 20px);height: calc(100% - 55px);margin-top: 10px;margin-left: 10px;">
						
					</div>
					<div class="one_product_text">
						移动小车远程控制软件
					</div>
				</div>
				<div class="phone_one_product_layout"  @click="change_show_product_list_sub_index('2_3')" >
					<div class="one_product_img one_product_img_9" style="width: calc(100% - 20px);height: calc(100% - 55px);margin-top: 10px;margin-left: 10px;">
						
					</div>
					<div class="one_product_text">
						AMR/AGV调度软件
					</div>
				</div>
				<div class="phone_one_product_layout"  @click="change_show_product_list_sub_index('2_4')" style="margin-left: 10px;">
					<div class="one_product_img one_product_img_10" style="width: calc(100% - 20px);height: calc(100% - 55px);margin-top: 10px;margin-left: 10px;">
						
					</div>
					<div class="one_product_text">
						数字孪生可视化平台
					</div>
				</div>
				<div class="phone_one_product_layout"  @click="change_show_product_list_sub_index('2_5')" >
					<div class="one_product_img one_product_img_6" style="width: calc(100% - 20px);height: calc(100% - 55px);margin-top: 10px;margin-left: 10px;">
						
					</div>
					<div class="one_product_text">
						5G云平台
					</div>
				</div>
				<div class="phone_one_product_layout"  @click="change_show_product_list_sub_index('2_6')" style="margin-left: 10px;">
					<div class="one_product_img one_product_img_8" style="width: calc(100% - 20px);height: calc(100% - 55px);margin-top: 10px;margin-left: 10px;">
						
					</div>
					<div class="one_product_text">
						工业视觉软件
					</div>
				</div>
			</div>
			
			<div v-show="show_product_list_index=='3'&&!show_product_detail">
				<div class="phone_product_detail_more" style="width: 100%;height: auto;">
					◆ 特种设备运动控制软硬件定制；<br>
					◆ 针对行业特殊运动算法定制；<br>
					◆ 生产线自动化软件定制；<br>
					◆ 个性化需求定制；<br>
					◆ 提供定制中间件协议；<br>
					◆ 专机定制、系统集成；<br>
					◆ 定制原创性控制系统与本体结构；<br>
					◆ 根据客户要求，进行生产线自动化改造。<br>
					<div class="product_detail_more_img_2">
						
					</div>
				</div>

			</div>
			
			<div v-show="show_product_detail">
				<div  v-show="choose_product_detail.id==1">
					<div class="phone_product_detail_name">
						{{choose_product_detail.name}}
					</div>
					<div :class="choose_product_detail.img" >
					</div>
					<div class="phone_product_detail_text">												
					</div>
					<div class="phone_product_detail_content_img_list" style="padding-left: 40px;" >
						<div  style="width: 1010px;height: 100%;">
							<div class="product_detail_content_img_list_item one_product_img_11">
								
							</div>
							<div class="product_detail_content_img_list_item one_product_img_12">
								
							</div>
							<div class="product_detail_content_img_list_item one_product_img_20">
								
							</div>
							<div class="product_detail_content_img_list_item one_product_img_21">
								
							</div>
							<div class="product_detail_content_img_list_item one_product_img_22">
								
							</div>
							<div class="product_detail_content_img_list_item one_product_img_43">
								
							</div>
						</div>
				
					</div>
					<div class="product_detail_content_img_list_title">
						实际应用
					</div>
				</div>
				
				<div  v-show="choose_product_detail.id==2">
					<div class="phone_product_detail_name">
						{{choose_product_detail.name}}
					</div>
					<div :class="choose_product_detail.img">
					</div>
					<div class="phone_product_detail_text">		
					</div>
					<div class="phone_product_detail_content_img_list" style="padding-left: 40px;" >
						<div  style="width: 1010px;height: 100%;">
							<div class="product_detail_content_img_list_item one_product_img_23">
								
							</div>
							<div class="product_detail_content_img_list_item one_product_img_24">
								
							</div>
							<div class="product_detail_content_img_list_item one_product_img_25">
								
							</div>
							<div class="product_detail_content_img_list_item one_product_img_26">
								
							</div>
							<div class="product_detail_content_img_list_item one_product_img_27">
								
							</div>
							<div class="product_detail_content_img_list_item one_product_img_28">
								
							</div>
						</div>
					
					</div>
					<div class="product_detail_content_img_list_title">
						实际应用
					</div>
				</div>
				
				<div  v-show="choose_product_detail.id==3">
					<div class="phone_product_detail_name">
						{{choose_product_detail.name}}
					</div>
					<div :class="choose_product_detail.img">
					</div>
					<div class="phone_product_detail_text">		
					</div>
					<div class="phone_product_detail_content_img_list" style="padding-left: 40px;" >
						<div  style="width: 846px;height: 100%;">
							<div class="product_detail_content_img_list_item one_product_img_38">
								
							</div>
							<div class="product_detail_content_img_list_item one_product_img_39">
								
							</div>
							<div class="product_detail_content_img_list_item one_product_img_40">
								
							</div>
							<div class="product_detail_content_img_list_item one_product_img_41">
								
							</div>
							<div class="product_detail_content_img_list_item one_product_img_42">
								
							</div>
						</div>
					
					</div>
					<div class="product_detail_content_img_list_title">
						实际应用
					</div>
				</div>
				
				<div  v-show="choose_product_detail.id==4">
					<div class="phone_product_detail_name">
						{{choose_product_detail.name}}
					</div>
					<div :class="choose_product_detail.img">
					</div>
					<div class="phone_product_detail_text">		
					</div>
					<div class="phone_product_detail_content_img_list" style="padding-left: 40px;" >
						<div  style="width: 1515px;height: 100%;">
							<div class="product_detail_content_img_list_item one_product_img_29">
								
							</div>
							<div class="product_detail_content_img_list_item one_product_img_37">
								
							</div>
							<div class="product_detail_content_img_list_item one_product_img_31">
								
							</div>
							<div class="product_detail_content_img_list_item one_product_img_32">
								
							</div>
							<div class="product_detail_content_img_list_item one_product_img_33">
								
							</div>
							<div class="product_detail_content_img_list_item one_product_img_34">
								
							</div>
							<div class="product_detail_content_img_list_item one_product_img_35">
								
							</div>
							<div class="product_detail_content_img_list_item one_product_img_36">
								
							</div>
							<div class="product_detail_content_img_list_item one_product_img_30">
								
							</div>
						</div>
					
					</div>
					<div class="product_detail_content_img_list_title">
						实际应用
					</div>
				</div>
				
				<div  v-show="choose_product_detail.id>=5">
					<div class="phone_product_detail_name">
						{{choose_product_detail.name}}
					</div>
					<div :class="choose_product_detail.img">
					</div>
					<div class="phone_product_detail_text">		
					</div>
				</div>
				
			</div>
			
			<div class="phone_show_video_layout_2" v-if="is_use_phone">
				<div style="position: absolute;width: 100%;height: 500px;background-color: #55ffff;z-index: 100;" v-show="false">
					
				</div>
<!-- 				<video id="video1" src="../assets/video3_20240412_11361392.mp4" controls loop controlslist="nodownload" muted  preload="meta" class="phone_video_style" style="object-fit:fill;width: 100%;max-width: 575px;height: calc((100vw - 20px)/1.77);max-height: 324px;" poster="../assets/new_img_7.png" >
					3
				</video> -->
				
	<!-- 			<img src="../assets/video3_20240417_15070483[00h00m00s-00h00m19s].gif" class="phone_video_style" style="width: 100%;max-width: 575px;height: calc((100vw - 20px)/1.77);max-height: 324px;" />
				<img src="../assets/video1_20240417_15141379[00h00m07s-00h00m25s].gif" class="phone_video_style" style="width: 100%;max-width: 575px;height: calc((100vw - 20px)/1.77);max-height: 400px;" />
				<img src="../assets/video4_20240417_15255057[00h00m00s-00h00m08s].gif" class="phone_video_style" style="width: 100%;max-width: 575px;height: calc((100vw - 20px)/1.77);max-height: 400px;" />
				<img src="../assets/video5_20240417_15275771[00h00m00s-00h00m11s].gif" class="phone_video_style" style="width: 100%;max-width: 575px;height: calc((100vw - 20px)/1.77);max-height: 400px;" />
				<img src="../assets/video6_20240417_15295023[00h00m00s-00h00m08s].gif" class="phone_video_style" style="width: 100%;max-width: 575px;height: calc((100vw - 20px)/1.77);max-height: 400px;" />
				<img src="../assets/video7_20240417_15345182[00h00m00s-00h00m10s].gif" class="phone_video_style" style="width: 100%;max-width: 575px;height: calc((100vw - 20px)/1.77);max-height: 400px;" />
				<img src="../assets/video8_20240417_15333233[00h00m00s-00h00m12s].gif" class="phone_video_style" style="width: 100%;max-width: 575px;height: calc((100vw - 20px)/1.77);max-height: 400px;" />
				<img src="../assets/video9__20240417_15354855[00h00m00s-00h00m05s].gif" class="phone_video_style" style="width: 100%;max-width: 575px;height: calc((100vw - 20px)/1.77);max-height: 400px;" /> -->
				
<!-- 				<img id="video_img_3" src="../assets/video3_20240417_15070483[00h00m00s-00h00m19s].gif" class="phone_video_style" style="width: 100%;max-width: 575px;height: calc((100vw - 20px)/1.77);max-height: 324px;" @click="play_video_img(3)"/>
				<img id="video_img_1" src="../assets/video1_20240417_15141379[00h00m07s-00h00m25s].gif" class="phone_video_style" style="width: 100%;max-width: 575px;height: calc((100vw - 20px)/1.77);max-height: 324px;" @click="play_video_img(1)"/>
				<img id="video_img_4" src="../assets/video4_20240417_15255057[00h00m00s-00h00m08s].gif" class="phone_video_style" style="width: 100%;max-width: 575px;height: calc((100vw - 20px)/1.77);max-height: 324px;" @click="play_video_img(4)"/>
				<img id="video_img_5" src="../assets/video5_20240417_15275771[00h00m00s-00h00m11s].gif" class="phone_video_style" style="width: 100%;max-width: 575px;height: calc((100vw - 20px)/1.77);max-height: 324px;" @click="play_video_img(5)"/>
				<img id="video_img_6" src="../assets/video6_20240417_15295023[00h00m00s-00h00m08s].gif" class="phone_video_style" style="width: 100%;max-width: 575px;height: calc((100vw - 20px)/1.77);max-height: 324px;" @click="play_video_img(6)"/>
				<img id="video_img_7" src="../assets/video7_20240417_15345182[00h00m00s-00h00m10s].gif" class="phone_video_style" style="width: 100%;max-width: 575px;height: calc((100vw - 20px)/1.77);max-height: 324px;" @click="play_video_img(7)"/>
				<img id="video_img_8" src="../assets/video8_20240417_15333233[00h00m00s-00h00m12s].gif" class="phone_video_style" style="width: 100%;max-width: 575px;height: calc((100vw - 20px)/1.77);max-height: 324px;" @click="play_video_img(8)"/>
				<img id="video_img_9" src="../assets/video9__20240417_15354855[00h00m00s-00h00m05s].gif" class="phone_video_style" style="width: 100%;max-width: 575px;height: calc((100vw - 20px)/1.77);max-height: 324px;" @click="play_video_img(9)"/> -->
				<!-- <img :src="require('../assets/video1_img.jpg')" class="phone_video_style" style="width: 100%;max-width: 575px;height: calc((100vw - 20px)/1.77);max-height: 324px;"/> -->
				
				
				<video class="video-js phone_video_style vjs-big-play-centered" id="playerId_1" poster="../assets/new_img_7.png" style="width: 100% !important;max-width: 575px !important;height: calc((100vw - 20px)/1.77) !important;max-height: 324px !important;">
					您的浏览器不支持视频标签。3
				</video>
<!-- 				<video class="video-js phone_video_style vjs-big-play-centered" id="playerId_1" poster="../assets/new_img_7.png" style="width: 100% !important;max-width: 380px !important;height: calc((100vw - 20px)/1.77) !important;max-height: 210px !important;">
					您的浏览器不支持视频标签。3
				</video> -->

				<video class="video-js phone_video_style vjs-big-play-centered" id="playerId_2" poster="../assets/video1_img.jpg">
					您的浏览器不支持视频标签。1
				</video>
				<video class="video-js phone_video_style vjs-big-play-centered" id="playerId_3" poster="../assets/video4_img.jpg">
					您的浏览器不支持视频标签。2
				</video>
				<video class="video-js phone_video_style vjs-big-play-centered" id="playerId_4" poster="../assets/video5_img.jpg">
					您的浏览器不支持视频标签。3
				</video>
				<video class="video-js phone_video_style vjs-big-play-centered" id="playerId_5" poster="../assets/video6_img.jpg">
					您的浏览器不支持视频标签。4
				</video>
				<video class="video-js phone_video_style vjs-big-play-centered" id="playerId_6" poster="../assets/video7_img.jpg">
					您的浏览器不支持视频标签。5
				</video>
				<video class="video-js phone_video_style vjs-big-play-centered" id="playerId_7" poster="../assets/video8_img.jpg">
					您的浏览器不支持视频标签。6
				</video>
				<video class="video-js phone_video_style vjs-big-play-centered" id="playerId_8" poster="../assets/video9_img.jpg">
					您的浏览器不支持视频标签。7
				</video>
				<video class="video-js phone_video_style vjs-big-play-centered" id="playerId_9" poster="../assets/video10_img.jpg">
					您的浏览器不支持视频标签。8
				</video>		
				<div class="more_img">
					
				</div>

			</div>

			
		</div>
	</div>
	<div class="page_layout_3">
		
		<div class="product_list_layout">
			<div class="product_list_menu">
				<div class="product_list_menu_title" >
					<div style="font-size: 28px;font-weight: 600;line-height: 55px;">
						产品介绍
					</div>
					<div style="margin-top: -15px;font-size: 16px;line-height: 25px;">
						Product Introduction
					</div>
				</div>
				<!-- <div class="product_list_item" @click="change_show_product_list_index(1)"> -->
				<div :class="show_product_list_index=='1'?'product_list_item choose_product_list_item':'product_list_item'" @click="change_show_product_list_index(1)">
					<div class="choose_product_list_item_shadow">
						
					</div>
					<div :class="show_product_list_index=='1'?'product_list_item_icon_choose':'product_list_item_icon'" style="width: 40px;height: 40px;"></div>
					<!-- <div class="product_list_item_icon"></div> -->
					
					<div class="product_list_item_text" style="padding-left: 10px;">控制器</div>
				</div>
				<!-- v-show="show_product_list_index=='1'" -->
				<div class="product_list_sub_layout" style="height: 185px;" >
					<div :class="show_product_list_index_sub=='1_1'?'product_list_item_sub choose_product_list_item':'product_list_item_sub'" @click="change_show_product_list_sub_index('1_1')">
						<div :class="show_product_list_index_sub=='1_1'?'product_list_item_icon_sub_choose':'product_list_item_icon_sub'"></div>
						<div class="product_list_item_text" style="font-size: 18px;">机器人控制器</div>
					</div>
					<div :class="show_product_list_index_sub=='1_2'?'product_list_item_sub choose_product_list_item':'product_list_item_sub'" @click="change_show_product_list_sub_index('1_2')">
						<div :class="show_product_list_index_sub=='1_2'?'product_list_item_icon_sub_choose':'product_list_item_icon_sub'"></div>
						<div class="product_list_item_text" style="font-size: 18px;">AMR/AGV控制器</div>
					</div>
					<div :class="show_product_list_index_sub=='1_3'?'product_list_item_sub choose_product_list_item':'product_list_item_sub'" @click="change_show_product_list_sub_index('1_3')">
						<div :class="show_product_list_index_sub=='1_3'?'product_list_item_icon_sub_choose':'product_list_item_icon_sub'"></div>
						<div class="product_list_item_text" style="font-size: 18px;">PLC控制器</div>
					</div>
					<div :class="show_product_list_index_sub=='1_4'?'product_list_item_sub choose_product_list_item':'product_list_item_sub'" @click="change_show_product_list_sub_index('1_4')">
						<div :class="show_product_list_index_sub=='1_4'?'product_list_item_icon_sub_choose':'product_list_item_icon_sub'"></div>
						<div class="product_list_item_text" style="font-size: 18px;">视觉控制器</div>
					</div>
				</div>
				

				
				
				<div :class="show_product_list_index=='2'?'product_list_item choose_product_list_item':'product_list_item'" @click="change_show_product_list_index(2)">
					<div :class="show_product_list_index=='2'?'product_list_item_icon_choose':'product_list_item_icon'" style="width: 40px;height: 40px;"></div>
					<div class="product_list_item_text" style="padding-left: 10px;">集成软件</div>
				</div>
				<div class="product_list_sub_layout">
					<div :class="show_product_list_index_sub=='2_1'?'product_list_item_sub choose_product_list_item':'product_list_item_sub'" @click="change_show_product_list_sub_index('2_1')">
						<div :class="show_product_list_index_sub=='2_1'?'product_list_item_icon_sub_choose':'product_list_item_icon_sub'"></div>
						<div class="product_list_item_text" style="font-size: 18px;">机器人离线编程软件</div>
					</div>
					<div :class="show_product_list_index_sub=='2_2'?'product_list_item_sub choose_product_list_item':'product_list_item_sub'" @click="change_show_product_list_sub_index('2_2')">
						<div :class="show_product_list_index_sub=='2_2'?'product_list_item_icon_sub_choose':'product_list_item_icon_sub'"></div>
						<div class="product_list_item_text" style="font-size: 18px;">移动小车远程控制软件</div>
					</div>
					<div :class="show_product_list_index_sub=='2_3'?'product_list_item_sub choose_product_list_item':'product_list_item_sub'" @click="change_show_product_list_sub_index('2_3')">
						<div :class="show_product_list_index_sub=='2_3'?'product_list_item_icon_sub_choose':'product_list_item_icon_sub'"></div>
						<div class="product_list_item_text" style="font-size: 18px;">AMR/AGV调度软件</div>
					</div>
					<div :class="show_product_list_index_sub=='2_4'?'product_list_item_sub choose_product_list_item':'product_list_item_sub'" @click="change_show_product_list_sub_index('2_4')">
						<div :class="show_product_list_index_sub=='2_4'?'product_list_item_icon_sub_choose':'product_list_item_icon_sub'"></div>
						<div class="product_list_item_text" style="font-size: 18px;">数字孪生可视化平台</div>
					</div>
					<div :class="show_product_list_index_sub=='2_5'?'product_list_item_sub choose_product_list_item':'product_list_item_sub'" @click="change_show_product_list_sub_index('2_5')">
						<div :class="show_product_list_index_sub=='2_5'?'product_list_item_icon_sub_choose':'product_list_item_icon_sub'"></div>
						<div class="product_list_item_text" style="font-size: 18px;">5G云平台</div>
					</div>
					<div :class="show_product_list_index_sub=='2_6'?'product_list_item_sub choose_product_list_item':'product_list_item_sub'" @click="change_show_product_list_sub_index('2_6')">
						<div :class="show_product_list_index_sub=='2_6'?'product_list_item_icon_sub_choose':'product_list_item_icon_sub'"></div>
						<div class="product_list_item_text" style="font-size: 18px;">工业视觉软件</div>
					</div>
				</div>

				<div :class="show_product_list_index=='3'?'product_list_item choose_product_list_item':'product_list_item'" @click="change_show_product_list_index(3)">
					<div :class="show_product_list_index=='3'?'product_list_item_icon_choose':'product_list_item_icon'" style="width: 40px;height: 40px;"></div>
					<div class="product_list_item_text" style="padding-left: 10px;">特种设备定制</div>
				</div>
				
			</div>
			<div class="product_list_content">
				<div class="product_list_menu_title" >
					<div style="font-size: 28px;font-weight: 600;line-height: 55px;">
						产品介绍
					</div>
					<div style="margin-top: -15px;font-size: 16px;line-height: 25px;">
						Product Introduction
					</div>
				</div>
				<div class="one_product_list" v-show="show_product_list_index=='1'&&!show_product_detail">
					<div class="one_product_layout" @click="choose_one_product(1)" >
						<div class="one_product_img one_product_img_1" style="width: 280px;height: 180px;margin-top: 10px;margin-left: 20px;">
							
						</div>
						<div class="one_product_text">
							机器人控制器
						</div>
					</div>
					<div class="one_product_layout " @click="choose_one_product(2)" style="margin-left: 20px;">
						<div class="one_product_img one_product_img_2" style="width: 280px;height: 180px;margin-top: 10px;margin-left: 10px;">
							
						</div>
						<div class="one_product_text">
							AMR/AGV控制器
						</div>
					</div>
					<div class="one_product_layout " @click="choose_one_product(3)" style="margin-left: 20px;">
						<div class="one_product_img one_product_img_4" style="width: 280px;height: 180px;margin-top: 10px;margin-left: 10px;">
							
						</div>
						<div class="one_product_text">
							PLC控制器
						</div>
					</div>
					<div class="one_product_layout " @click="choose_one_product(4)" >
						<div class="one_product_img one_product_img_18" style="width: 250px;height: 180px;margin-top: 10px;margin-left: 35px;">
							
						</div>
						<div class="one_product_text">
							视觉控制器
						</div>
					</div>
					
					
				</div>
				
				<div class="one_product_list" v-show="show_product_list_index=='2'&&!show_product_detail">
					<div class="one_product_layout"  @click="choose_one_product(5)">
						<div class="one_product_img one_product_img_5" style="width: 300px;height: 180px;margin-top: 10px;margin-left: 10px;">
							
						</div>
						<div class="one_product_text">
							机器人离线编程软件
						</div>
					</div>
					<div class="one_product_layout"  @click="choose_one_product(7)" style="margin-left: 20px;">
						<div class="one_product_img one_product_img_7" style="width: 300px;height: 180px;margin-top: 10px;margin-left: 10px;">
							
						</div>
						<div class="one_product_text">
							移动小车远程控制软件
						</div>
					</div>
					<div class="one_product_layout"  @click="choose_one_product(9)" style="margin-left: 20px;">
						<div class="one_product_img one_product_img_9" style="width: 300px;height: 180px;margin-top: 10px;margin-left: 10px;">
							
						</div>
						<div class="one_product_text">
							AMR/AGV调度软件
						</div>
					</div>
					<div class="one_product_layout"  @click="choose_one_product(10)">
						<div class="one_product_img one_product_img_10" style="width: 300px;height: 180px;margin-top: 10px;margin-left: 10px;">
							
						</div>
						<div class="one_product_text">
							数字孪生可视化平台
						</div>
					</div>
					<div class="one_product_layout"  @click="choose_one_product(6)" style="margin-left: 20px;">
						<div class="one_product_img one_product_img_6" style="width: 300px;height: 180px;margin-top: 10px;margin-left: 10px;">
							
						</div>
						<div class="one_product_text">
							5G云平台
						</div>
					</div>
					<div class="one_product_layout"  @click="choose_one_product(8)" style="margin-left: 20px;">
						<div class="one_product_img one_product_img_8" style="width: 300px;height: 180px;margin-top: 10px;margin-left: 10px;">
							
						</div>
						<div class="one_product_text">
							工业视觉软件
						</div>
					</div>
				</div>
				
				<div class="one_product_list" style="height: 340px;" v-show="show_product_list_index=='3'&&!show_product_detail" >
					<div class="product_detail_more" >
						<div style="width: 320px;height: 100%;float: left;text-align: left;padding-left: 20px;padding-top: 20px;line-height: 45px;font-size: 18px;">
							◆ 特种设备运动控制软硬件定制；<br>
							◆ 针对行业特殊运动算法定制；<br>
							◆ 生产线自动化软件定制；<br>
							◆ 个性化需求定制；<br>
							◆ 提供定制中间件协议；<br>
					
						</div>
						<div style="width: 355px;height: 100%;float: left;text-align: left;padding-left: 10px;padding-top: 20px;line-height: 45px;font-size: 18px;">
							◆ 专机定制、系统集成；<br>
							◆ 定制原创性控制系统与本体结构；<br>
							◆ 根据客户要求，进行生产线自动化改造。<br>
							<div class="product_detail_more_btn" @click="jump_to_consultation">
								详情咨询
							</div>
						</div>
						<div class="product_detail_more_img_2">
							
						</div>
					</div>
				</div>
					
				<div class="show_product_detail_layout" v-show="false">
					<div class="one_product_layout"  @click="choose_one_product(11)">
						<div class="one_product_img one_product_img_11" style="width: 280px;height: 200px;margin-top: 10px;margin-left: 10px;">
							
						</div>
						<div class="one_product_text">
							工业机器人
						</div>
					</div>
					<div class="one_product_layout"  @click="choose_one_product(12)">
						<div class="one_product_img one_product_img_12" style="width: 280px;height: 200px;margin-top: 10px;margin-left: 10px;">
							
						</div>
						<div class="one_product_text">
							协作机器人
						</div>
					</div>
					<div class="one_product_layout"  @click="choose_one_product(13)">
						<div class="one_product_img one_product_img_13" style="height: 200px;width: 280px;margin-top: 10px;margin-left: 10px;">
							
						</div>
						<div class="one_product_text">
							双臂机器人
						</div>
					</div>
					<div class="one_product_layout"  @click="choose_one_product(14)">
						<div class="one_product_img one_product_img_14" style="width: 280px;height: 200px;margin-top: 10px;margin-left: 10px;">
							
						</div>
						<div class="one_product_text">
							移动机器人
						</div>
					</div>
					<!-- <div class="one_product_layout" @click="choose_one_product(10)"> -->
					<div class="one_product_layout"  @click="choose_one_product(15)">
						<div class="one_product_img one_product_img_15" style="width: 270px;height: 200px;margin-top: 10px;margin-left: 20px;">
							
						</div>
						<div class="one_product_text">
							三轴平台
						</div>
					</div>
				</div>
				
				<div class="show_product_detail_layout" v-show="show_product_detail&&choose_product_detail.id>=1&&choose_product_detail.id<=4">
<!-- 					<div class="product_detail_content" v-if="choose_product_detail.id==-1">
						<div class="product_detail_content_title">
							机器人控制器
						</div>
						<div class="product_detail_content_1_1" >
							<div class="one_product_img_19" 
								style="
									height: calc(100% - 50px );
									margin-top: 25px;
									width: calc(100% - 50px);
									margin-left: 25px;
							">
								
							</div>
							
						</div>
						
						<div class="product_detail_content_1_1" style="margin-top: 220px;">
							<div class="one_product_img_16"
								style="
									height: calc(100% - 0px );
									margin-top: 0px;
									width: calc(100% - 50px);
									margin-left: 25px;
							">
								
							</div>
						</div>
						
						<div class="product_detail_content_1_1" style="margin-top: 430px;">
							<div class="one_product_img_17"
								style="
									height: calc(100% - 50px );
									margin-top: 25px;
									width: calc(100% - 50px);
									margin-left: 25px;
							">
								
							</div>
						</div>
						<div class="product_detail_content_1_2">
							
							<div class="product_detail_content_1_2_one_text" style="margin-top: 10px;">
								1、复合型机器人，集移动机器人和工业机器人两项功能为一身;									
							</div>
							<div class="product_detail_content_1_2_one_text">
								2、机动性强，在空间内自主移动、群体作业并为应用提供绝对的灵活性;
							</div>
							<div class="product_detail_content_1_2_one_text">
								3、能够自主检测、规划、导航、避障及充电，完成抓取、运输、装配等高难度工作;
							</div>
							<div class="product_detail_content_1_2_one_text">
								4、配备完善的安全系统及模块化的组件;
							</div>
							<div class="product_detail_content_1_2_one_text">
								5、自主开发控制系统，自有知识产权。
							</div>
							<div class="product_detail_content_1_2_one_item" style="margin-top: 50px;">
								掌控核心技术，拥有自主产权；
							</div>
							<div class="product_detail_content_1_2_one_item">
								算法不输国外行业最先进同类产品；
							</div>
							<div class="product_detail_content_1_2_one_item">
								实现平面及三维空间任意直线、圆弧的插补，可以分解任意轨迹；
							</div>
							<div class="product_detail_content_1_2_one_item">
								自主编译语言和界面；
							</div>
							<div class="product_detail_content_1_2_one_item">
								实现离线编程，三维动态仿真；
							</div>
							<div class="product_detail_content_1_2_one_item">
								可实现多轴联动，处于行业领先水平。
							</div>
							

						</div>
						
						<div class="product_detail_content_img_list" >
							
						</div>
						
					</div> -->
					
					<div  v-show="choose_product_detail.id==1">
						<div :class="choose_product_detail.img" >
						</div>
						<div class="product_detail_name">
							{{choose_product_detail.name}}
						</div>
						<div class="product_detail_text">												
						</div>
						<div class="product_detail_content_img_list" style="padding-left: 40px;" >
							<div  style="width: 1250px;height: 100%;">
								<div class="product_detail_content_img_list_item one_product_img_11">
									
								</div>
								<div class="product_detail_content_img_list_item one_product_img_12">
									
								</div>
								<div class="product_detail_content_img_list_item one_product_img_20">
									
								</div>
								<div class="product_detail_content_img_list_item one_product_img_21">
									
								</div>
								<div class="product_detail_content_img_list_item one_product_img_22">
									
								</div>
								<div class="product_detail_content_img_list_item one_product_img_43">
									
								</div>
							</div>

						</div>
						<div class="product_detail_content_img_list_title">
							实际应用
						</div>
					</div>
					
					<div  v-show="choose_product_detail.id==2">
						<div :class="choose_product_detail.img">
						</div>
						<div class="product_detail_name">
							{{choose_product_detail.name}}
						</div>
						<div class="product_detail_text">		
						</div>
						<div class="product_detail_content_img_list" style="padding-left: 40px;" >
							<div  style="width: 1250px;height: 100%;">
								<div class="product_detail_content_img_list_item one_product_img_23">
									
								</div>
								<div class="product_detail_content_img_list_item one_product_img_24">
									
								</div>
								<div class="product_detail_content_img_list_item one_product_img_25">
									
								</div>
								<div class="product_detail_content_img_list_item one_product_img_26">
									
								</div>
								<div class="product_detail_content_img_list_item one_product_img_27">
									
								</div>
								<div class="product_detail_content_img_list_item one_product_img_28">
									
								</div>
							</div>
						
						</div>
						<div class="product_detail_content_img_list_title">
							实际应用
						</div>
					</div>
					
					<div  v-show="choose_product_detail.id==3">
						<div :class="choose_product_detail.img">
						</div>
						<div class="product_detail_name">
							{{choose_product_detail.name}}
						</div>
						<div class="product_detail_text">		
						</div>
						<div class="product_detail_content_img_list" style="padding-left: 40px;" >
							<div  style="width: 1046px;height: 100%;">
								<div class="product_detail_content_img_list_item one_product_img_38">
									
								</div>
								<div class="product_detail_content_img_list_item one_product_img_39">
									
								</div>
								<div class="product_detail_content_img_list_item one_product_img_40">
									
								</div>
								<div class="product_detail_content_img_list_item one_product_img_41">
									
								</div>
								<div class="product_detail_content_img_list_item one_product_img_42">
									
								</div>
							</div>
						
						</div>
						<div class="product_detail_content_img_list_title">
							实际应用
						</div>
					</div>
					
					<div  v-show="choose_product_detail.id==4">
						<div :class="choose_product_detail.img">
						</div>
						<div class="product_detail_name">
							{{choose_product_detail.name}}
						</div>
						<div class="product_detail_text">		
						</div>
						<div class="product_detail_content_img_list" style="padding-left: 40px;" >
							<div  style="width: 1875px;height: 100%;">
								<div class="product_detail_content_img_list_item one_product_img_29">
									
								</div>
								<div class="product_detail_content_img_list_item one_product_img_37">
									
								</div>
								<div class="product_detail_content_img_list_item one_product_img_31">
									
								</div>
								<div class="product_detail_content_img_list_item one_product_img_32">
									
								</div>
								<div class="product_detail_content_img_list_item one_product_img_33">
									
								</div>
								<div class="product_detail_content_img_list_item one_product_img_34">
									
								</div>
								<div class="product_detail_content_img_list_item one_product_img_35">
									
								</div>
								<div class="product_detail_content_img_list_item one_product_img_36">
									
								</div>
								<div class="product_detail_content_img_list_item one_product_img_30">
									
								</div>
							</div>
						
						</div>
						<div class="product_detail_content_img_list_title">
							实际应用
						</div>
					</div>

					<div class="product_detail_more" v-if="false">
						<div style="width: 350px;height: 100%;float: left;text-align: left;padding-left: 20px;padding-top: 6px;line-height: 31px;">
							◆ 特种设备运动控制软硬件定制；<br>
							◆ 针对行业特殊运动算法定制；<br>
							◆ 生产线自动化软件定制；<br>
							◆ 个性化需求定制；<br>
							◆ 提供定制中间件协议；<br>
							◆ 专机定制、系统集成；<br>

						</div>
						<div style="width: 400px;height: 100%;float: left;text-align: left;padding-left: 20px;padding-top: 6px;line-height: 31px;">
							◆ 定制原创性控制系统与本体结构；<br>
							◆ 根据客户要求，进行生产线自动化改造。<br>
							<div class="product_detail_more_btn" @click="jump_to_consultation">
								详情咨询
							</div>
						</div>						<div class="product_detail_more_img">
							
						</div>
					</div>
					
				</div>
				
				<div class="show_product_detail_layout_2" v-show="show_product_detail&&choose_product_detail.id>=5">
					<div  v-show="choose_product_detail.id>=5">
						<div :class="choose_product_detail.img">
						</div>
						<div class="product_detail_name">
							{{choose_product_detail.name}}
						</div>
						<div class="product_detail_text">		
						</div>
					</div>
				</div>
				
				<div class="show_video_layout_2"  v-if="!is_use_phone">
					<div style="width: 2490px;height: 324px;overflow: hidden;">

<!-- 						<video src="../assets/video2_20240410_11295262.mp4"  autoplay loop preload="auto" width="573px" height="324px" style="float: left;">
							2
						</video> -->
						<video id="video1" src="../assets/1.mp4" muted controls loop preload="auto" width="575px" height="324px" controlslist="nodownload" style="float: left;" poster="../assets/new_img_7.png" >
							1
						</video>

						<video id="video2" src="../assets/2.mp4" muted controls loop preload="auto" width="184px" height="324px" controlslist="nodownload" style="float: left;" poster="../assets/video4_img.jpg" >
							2
						</video>
						
						<video id="video3" src="../assets/3.mp4" muted controls  loop preload="auto" width="184px" height="324px" controlslist="nodownload" style="float: left;" poster="../assets/video1_img.jpg" >
							3
						</video>
						<video id="video4" src="../assets/4.mp4" muted controls loop preload="auto" width="184px" height="324px" controlslist="nodownload" style="float: left;" poster="../assets/video5_img.jpg" >
							4
						</video>
						
						<video id="video5" src="../assets/5.mp4" muted controls loop preload="auto" width="184px" height="324px" controlslist="nodownload" style="float: left;" poster="../assets/video6_img.jpg" >
							5
						</video>
						
						<video id="video6" src="../assets/6.mp4" muted controls loop preload="auto" width="184px" height="324px" controlslist="nodownload" style="float: left;" poster="../assets/video7_img.jpg" >
							6
						</video>
						<video id="video7" src="../assets/7.mp4" muted controls loop preload="auto" width="184px" height="324px" controlslist="nodownload" style="float: left;" poster="../assets/video8_img.jpg" >
							7
						</video>						
						<video id="video8" src="../assets/8.mp4" muted controls loop preload="auto" width="184px" height="324px" controlslist="nodownload" style="float: left;" poster="../assets/video9_img.jpg" >
							8
						</video>
						<video id="video9" src="../assets/9.mp4" muted controls loop preload="auto" width="184px" height="324px" controlslist="nodownload" style="float: left;"  poster="../assets/video10_img.jpg">
							9
						</video>
						<div class="more_img" style="width: 440px;height: 324px;float: left;">
							
						</div>
					</div>
			</div>
		</div>

		
		</div>
	</div>
	<div style="width: 1px;height: 1px;background-color: aqua;overflow: hidden">
		<div class="one_product_img_1" style="width: 50px;height: 50px;float: left;"></div>
		<div class="one_product_img_2" style="width: 50px;height: 50px;float: left;"></div>
		<div class="one_product_img_4" style="width: 50px;height: 50px;float: left;"></div>
		<div class="one_product_img_18" style="width: 50px;height: 50px;float: left;"></div>
		<div class="one_product_img_11" style="width: 50px;height: 50px;float: left;"></div>
		<div class="one_product_img_12" style="width: 50px;height: 50px;float: left;"></div>
		<div class="one_product_img_20" style="width: 50px;height: 50px;float: left;"></div>
		<div class="one_product_img_21" style="width: 50px;height: 50px;float: left;"></div>
		<div class="one_product_img_22" style="width: 50px;height: 50px;float: left;"></div>
		<div class="one_product_img_43" style="width: 50px;height: 50px;float: left;"></div>
		<div class="one_product_img_23" style="width: 50px;height: 50px;float: left;"></div>
		<div class="one_product_img_24" style="width: 50px;height: 50px;float: left;"></div>
		<div class="one_product_img_25" style="width: 50px;height: 50px;float: left;"></div>
		<div class="one_product_img_26" style="width: 50px;height: 50px;float: left;"></div>
		<div class="one_product_img_27" style="width: 50px;height: 50px;float: left;"></div>
		<div class="one_product_img_28" style="width: 50px;height: 50px;float: left;"></div>
		<div class="one_product_img_38" style="width: 50px;height: 50px;float: left;"></div>
		<div class="one_product_img_39" style="width: 50px;height: 50px;float: left;"></div>
		<div class="one_product_img_40" style="width: 50px;height: 50px;float: left;"></div>
		<div class="one_product_img_41" style="width: 50px;height: 50px;float: left;"></div>
		<div class="one_product_img_42" style="width: 50px;height: 50px;float: left;"></div>
		<div class="one_product_img_29" style="width: 50px;height: 50px;float: left;"></div>
		<div class="one_product_img_37" style="width: 50px;height: 50px;float: left;"></div>
		<div class="one_product_img_31" style="width: 50px;height: 50px;float: left;"></div>
		<div class="one_product_img_32" style="width: 50px;height: 50px;float: left;"></div>
		<div class="one_product_img_33" style="width: 50px;height: 50px;float: left;"></div>
		<div class="one_product_img_34" style="width: 50px;height: 50px;float: left;"></div>
		<div class="one_product_img_35" style="width: 50px;height: 50px;float: left;"></div>
		<div class="one_product_img_36" style="width: 50px;height: 50px;float: left;"></div>
		<div class="one_product_img_30" style="width: 50px;height: 50px;float: left;"></div>
		<div class="one_product_img_5" style="width: 50px;height: 50px;float: left;"></div>
		<div class="one_product_img_6" style="width: 50px;height: 50px;float: left;"></div>
		<div class="one_product_img_7" style="width: 50px;height: 50px;float: left;"></div>
		<div class="one_product_img_8" style="width: 50px;height: 50px;float: left;"></div>
		<div class="one_product_img_9" style="width: 50px;height: 50px;float: left;"></div>
		<div class="one_product_img_10" style="width: 50px;height: 50px;float: left;"></div>
		<div class="product_detail_more_img_2" style="width: 50px;height: 50px;float: left;"></div>
		
	</div>
	<Bottom></Bottom>
</template>

<script>
	import request from "@/utils/request";
	import Bottom from "@/components/bottom"
	import videojs from "video.js";
	import "video.js/dist/video-js.css";
	
	export default{
		name:"introduction",
		components:{
			Bottom
		},
		data() {
			return{
				base_url:SERVER_URL,
				show_product_list_index:"1",
				show_product_list_index_sub:"-1",
				show_product_detail:false,
				choose_product_detail:{},
				telephone:"",
				scroll_sign_1:1,
				scroll_phone_sign_1:1,
				scroll_timer_1:"",
				scroll_sign_2:1,
				scroll_phone_sign_2:1,
				scroll_timer_2:"",
				scroll_2_stop:false,
				scroll_phone_2_stop:false,
				play_video_img_id:-1,
				video_img_is_stop:true,
				player_1:"",
				player_2:"",
				player_3:"",
				player_4:"",
				player_5:"",
				player_6:"",
				player_7:"",
				player_8:"",
				player_9:"",
				is_use_phone:true,
			}
		},
		mounted(){
			this.telephone = this.$GLOBAL.telephone
			document.getElementsByClassName("mainlayout_router_view")[0].scrollTop=0
			// request.get(this.$GLOBAL.flask_web_local_url+'/get_map_data_info').then(res => {
			// 	// console.log(res)
			// })
			
			this.scroll_timer_2 = setInterval(this.update_scroll_2_value,10)
			let scroll_2_obj = document.getElementsByClassName("show_video_layout_2")
			setTimeout(()=>{
				if(scroll_2_obj[0]){
					scroll_2_obj[0].addEventListener('mouseenter', this.set_scroll_2_stop)
					scroll_2_obj[0].addEventListener('mouseleave', this.set_scroll_2_run)
				}
			},100)


			let scroll_phone_2_obj = document.getElementsByClassName("phone_show_video_layout_2")[0]
			if(scroll_phone_2_obj){
				// scroll_phone_2_obj.addEventListener('touchstart', this.set_scroll_phone_2_stop)
				// scroll_phone_2_obj.addEventListener('touchmove', this.set_scroll_phone_2_stop)
				// scroll_phone_2_obj.addEventListener('touchend', this.set_scroll_phone_2_run)
				// for(let i=1;i<=8;i++){
				// 	let video_obj = document.getElementById("video"+i)
				// 		video_obj.addEventListener('touchstart', this.set_scroll_phone_2_stop)
				// 		// video_obj.addEventListener('touchmove', this.set_scroll_phone_2_stop)
				// 		// video_obj.addEventListener('touchend', this.set_scroll_phone_2_run)
				// }
			}

			
			document.oncontextmenu = function(event){
			    event.returnValue = false;
			}
			
			let window_width = document.documentElement.clientWidth
			if(window_width<1400){
				this.is_use_phone = true
				// setTimeout(this.init_video_setting,300)
				this.init_video_setting()
			}else{
				this.is_use_phone = false
			}
					
			console.log(this.is_use_phone)
			
			
			// request.get(this.$GLOBAL.flask_web_local_url+'/get_video?width=280&height=380&name=1').then(res => {
			// 	console.log(res)
			// })
			
			// setTimeout(this.play_all_video,1500)
		},
		methods:{
			init_video_setting(){
				let userActions_data = {
					click:true,
					doubleClick:false,
					hotkeys:false
				}
				let controlBar_data = {
					progressControl:false,
					volumePanel:false,
					pictureInPictureToggle:false,
					remainingTimeDisplay:true,
					fullscreenToggle:false
				}
				
				this.player_1 = videojs("playerId_1", {autoplay: false,muted:true,controls:true,loop:true,playsinline:true,userActions:userActions_data,controlBar:controlBar_data});
				this.player_1.src(require('../assets/1.mp4'));
				
				this.player_2 = videojs("playerId_2", {autoplay: false,muted:true,controls:true,loop:true,playsinline:true,userActions:userActions_data,controlBar:controlBar_data});
				this.player_2.src(require('../assets/2.mp4'));
				
				this.player_3 = videojs("playerId_3", {autoplay: false,muted:true,controls:true,loop:true,playsinline:true,userActions:userActions_data,controlBar:controlBar_data});
				this.player_3.src(require('../assets/3.mp4'));
				
				this.player_4 = videojs("playerId_4", {autoplay: false,muted:true,controls:true,loop:true,playsinline:true,userActions:userActions_data,controlBar:controlBar_data});
				this.player_4.src(require('../assets/4.mp4'));		
				
				this.player_5 = videojs("playerId_5", {autoplay: false,muted:true,controls:true,loop:true,playsinline:true,userActions:userActions_data,controlBar:controlBar_data});
				this.player_5.src(require('../assets/5.mp4'));
												
				this.player_6 = videojs("playerId_6", {autoplay: false,muted:true,controls:true,loop:true,playsinline:true,userActions:userActions_data,controlBar:controlBar_data});
				this.player_6.src(require('../assets/6.mp4'));
												
				this.player_7 = videojs("playerId_7", {autoplay: false,muted:true,controls:true,loop:true,playsinline:true,userActions:userActions_data,controlBar:controlBar_data});
				this.player_7.src(require('../assets/7.mp4'));	
				
				this.player_8 = videojs("playerId_8", {autoplay: false,muted:true,controls:true,loop:true,playsinline:true,userActions:userActions_data,controlBar:controlBar_data});
				this.player_8.src(require('../assets/8.mp4'));
				
				this.player_9 = videojs("playerId_9", {autoplay: false,muted:true,controls:true,loop:true,playsinline:true,userActions:userActions_data,controlBar:controlBar_data});
				this.player_9.src(require('../assets/9.mp4'));
				
				// m3u8 ================================================
				// let that = this
				// this.player_1 = videojs("playerId_1", {
				// 	autoplay: false, // true,浏览器准备好时开始播放。
				// 	muted: true, // 默认情况下将会消除音频。
				// 	loop: true, // 导致视频一结束就重新开始。
				// 	controls: true, //取消视频中的进度条
				// 	language: 'zh-CN',  //汉化
				// 	sources: [{
				// 		type: 'application/x-mpegURL',
				// 		src: 'https://cnflyrobot.com/device/static/11_.m3u8'
				// 	}],
				// 	notSupportedMessage: '此视频暂无法播放，请稍后再试', // 无法播放媒体源时显示的默认信息。
				// 	textTrackDisplay: false,
				// 	controlBar:controlBar_data,
				// 	playsinline:true,
					
				// });
				// this.player_1.on("canplay",()=>{
					
				// 	that.player_2 = videojs("playerId_2", {
				// 		autoplay: false, // true,浏览器准备好时开始播放。
				// 		muted: true, // 默认情况下将会消除音频。
				// 		loop: true, // 导致视频一结束就重新开始。
				// 		controls: true, //取消视频中的进度条
				// 		language: 'zh-CN',  //汉化
				// 		sources: [{
				// 			type: 'application/x-mpegURL',
				// 			src: 'https://cnflyrobot.com/device/static/22_.m3u8'
				// 		}],
				// 		notSupportedMessage: '此视频暂无法播放，请稍后再试', // 无法播放媒体源时显示的默认信息。
				// 		textTrackDisplay: false,
				// 		controlBar:controlBar_data,
				// 		playsinline:true,
						
				// 	});
				// 	that.player_2.on("canplay",()=>{
				// 		that.player_3 = videojs("playerId_3", {
				// 			autoplay: false, // true,浏览器准备好时开始播放。
				// 			muted: true, // 默认情况下将会消除音频。
				// 			loop: true, // 导致视频一结束就重新开始。
				// 			controls: true, //取消视频中的进度条
				// 			language: 'zh-CN',  //汉化
				// 			sources: [{
				// 				type: 'application/x-mpegURL',
				// 				src: 'https://cnflyrobot.com/device/static/33_.m3u8'
				// 			}],
				// 			notSupportedMessage: '此视频暂无法播放，请稍后再试', // 无法播放媒体源时显示的默认信息。
				// 			textTrackDisplay: false,
				// 			controlBar:controlBar_data,
				// 			playsinline:true,
							
				// 		});
				// 		that.player_3.on("canplay",()=>{
				// 			that.player_4 = videojs("playerId_4", {
				// 				autoplay: false, // true,浏览器准备好时开始播放。
				// 				muted: true, // 默认情况下将会消除音频。
				// 				loop: true, // 导致视频一结束就重新开始。
				// 				controls: true, //取消视频中的进度条
				// 				language: 'zh-CN',  //汉化
				// 				sources: [{
				// 					type: 'application/x-mpegURL',
				// 					src: 'https://cnflyrobot.com/device/static/44_.m3u8'
				// 				}],
				// 				notSupportedMessage: '此视频暂无法播放，请稍后再试', // 无法播放媒体源时显示的默认信息。
				// 				textTrackDisplay: false,
				// 				controlBar:controlBar_data,
				// 				playsinline:true,
								
				// 			});
				// 			that.player_4.on("canplay",()=>{
				// 				that.player_5 = videojs("playerId_5", {
				// 					autoplay: false, // true,浏览器准备好时开始播放。
				// 					muted: true, // 默认情况下将会消除音频。
				// 					loop: true, // 导致视频一结束就重新开始。
				// 					controls: true, //取消视频中的进度条
				// 					language: 'zh-CN',  //汉化
				// 					sources: [{
				// 						type: 'application/x-mpegURL',
				// 						src: 'https://cnflyrobot.com/device/static/55_.m3u8'
				// 					}],
				// 					notSupportedMessage: '此视频暂无法播放，请稍后再试', // 无法播放媒体源时显示的默认信息。
				// 					textTrackDisplay: false,
				// 					controlBar:controlBar_data,
				// 					playsinline:true,
									
				// 				});
				// 				that.player_5.on("canplay",()=>{
				// 					that.player_6 = videojs("playerId_6", {
				// 						autoplay: false, // true,浏览器准备好时开始播放。
				// 						muted: true, // 默认情况下将会消除音频。
				// 						loop: true, // 导致视频一结束就重新开始。
				// 						controls: true, //取消视频中的进度条
				// 						language: 'zh-CN',  //汉化
				// 						sources: [{
				// 							type: 'application/x-mpegURL',
				// 							src: 'https://cnflyrobot.com/device/static/66_.m3u8'
				// 						}],
				// 						notSupportedMessage: '此视频暂无法播放，请稍后再试', // 无法播放媒体源时显示的默认信息。
				// 						textTrackDisplay: false,
				// 						controlBar:controlBar_data,
				// 						playsinline:true,
										
				// 					});
				// 					that.player_6.on("canplay",()=>{
				// 						that.player_7 = videojs("playerId_7", {
				// 							autoplay: false, // true,浏览器准备好时开始播放。
				// 							muted: true, // 默认情况下将会消除音频。
				// 							loop: true, // 导致视频一结束就重新开始。
				// 							controls: true, //取消视频中的进度条
				// 							language: 'zh-CN',  //汉化
				// 							sources: [{
				// 								type: 'application/x-mpegURL',
				// 								src: 'https://cnflyrobot.com/device/static/77_.m3u8'
				// 							}],
				// 							notSupportedMessage: '此视频暂无法播放，请稍后再试', // 无法播放媒体源时显示的默认信息。
				// 							textTrackDisplay: false,
				// 							controlBar:controlBar_data,
				// 							playsinline:true,
											
				// 						});
				// 						that.player_7.on("canplay",()=>{
				// 							that.player_8 = videojs("playerId_8", {
				// 								autoplay: false, // true,浏览器准备好时开始播放。
				// 								muted: true, // 默认情况下将会消除音频。
				// 								loop: true, // 导致视频一结束就重新开始。
				// 								controls: true, //取消视频中的进度条
				// 								language: 'zh-CN',  //汉化
				// 								sources: [{
				// 									type: 'application/x-mpegURL',
				// 									src: 'https://cnflyrobot.com/device/static/88_.m3u8'
				// 								}],
				// 								notSupportedMessage: '此视频暂无法播放，请稍后再试', // 无法播放媒体源时显示的默认信息。
				// 								textTrackDisplay: false,
				// 								controlBar:controlBar_data,
				// 								playsinline:true,
				// 							});											
				// 						})
				// 					})
				// 				})
				// 			})
				// 		})
				// 	})					
				// })
				
				
				
				// blob ================================================
				/*
				// let ttt_url = 'https://cnflyrobot.com/device/get_video_3?file_name=3_.mp4'
								// var xhr = new XMLHttpRequest();
								// xhr.open("GET", ttt_url, true);
								// xhr.responseType = "blob";
				//    xhr.onload = function() {
								// 	console.log(this.status)
								//     var blob = this.response;
								// 	document.getElementById("playerId_1").src = window.URL.createObjectURL(new Blob([blob],{type: 'video/mp4'})); 
								// }
								// xhr.send();
								
								// // let ttt_url = window.location.origin + require('../assets/video3_20240412_11361392.mp4')
								// // var xhr = new XMLHttpRequest();
								// // xhr.open("GET", ttt_url, true);
								// // xhr.responseType = "blob";
				// //    xhr.onload = function() {
								// // 	console.log(this.status)
								// //     var blob = this.response;
								// // 	document.getElementById("playerId_1").src = window.URL.createObjectURL(new Blob([blob],{type: 'video/mp4'})); 
								// // }
								// // xhr.send();
				*/


			},
			play_video_img(img_id){
				document.getElementById("video_img_3").src = 'https://cnflyrobot.com/device/get_upload_files?file_name=/fly_src/new_img_7.png'
				document.getElementById("video_img_1").src = 'https://cnflyrobot.com/device/get_upload_files?file_name=/fly_src/video1_img.jpg'
				document.getElementById("video_img_4").src = 'https://cnflyrobot.com/device/get_upload_files?file_name=/fly_src/video4_img.jpg'
				document.getElementById("video_img_5").src = 'https://cnflyrobot.com/device/get_upload_files?file_name=/fly_src/video5_img.jpg'
				document.getElementById("video_img_6").src = 'https://cnflyrobot.com/device/get_upload_files?file_name=/fly_src/video6_img.jpg'
				document.getElementById("video_img_7").src = 'https://cnflyrobot.com/device/get_upload_files?file_name=/fly_src/video7_img.jpg'
				document.getElementById("video_img_8").src = 'https://cnflyrobot.com/device/get_upload_files?file_name=/fly_src/video8_img.jpg'
				document.getElementById("video_img_9").src = 'https://cnflyrobot.com/device/get_upload_files?file_name=/fly_src/video9_img.jpg'
				if(this.play_video_img_id!=img_id){	
					this.play_video_img_id = img_id
					if(img_id==3){
						document.getElementById("video_img_3").src = 'https://cnflyrobot.com/device/get_video?width=500&height=200&name=3'
					}else{
						document.getElementById("video_img_"+img_id).src = 'https://cnflyrobot.com/device/get_video?width=260&height=300&name='+img_id
					}
				}else{	
					this.play_video_img_id = -1
				}
				console.log(img_id)
			},
			play_all_video(){
				document.getElementById("video1").play()
				document.getElementById("video2").play()
				document.getElementById("video3").play()
				document.getElementById("video4").play()
				document.getElementById("video5").play()
				document.getElementById("video6").play()
				document.getElementById("video7").play()
				document.getElementById("video8").play()
			},
			set_scroll_phone_2_stop(e){
				this.scroll_phone_2_stop = true
				if(e.touches[0].target.paused){
					e.touches[0].target.play()
				}else{
					e.touches[0].target.pause()
				}				
				console.log("stop")
			},
			set_scroll_phone_2_run(e){
				this.scroll_phone_2_stop = false
				// console.log(e)
				console.log("run")
			},
			set_scroll_2_stop(){
				this.scroll_2_stop = true
			},
			set_scroll_2_run(){
				this.scroll_2_stop = false
			},
			carousel_change(a,b){
				console.log(a)
				console.log(b)
			},
			update_scroll_2_value(){
				let obj = document.getElementsByClassName("show_video_layout_2")[0]
				if(obj){
					let scroll_width = obj.scrollWidth - obj.offsetWidth
					if(obj.scrollLeft >= scroll_width){
						this.scroll_sign_2 = -1
					}else if(obj.scrollLeft <= 0){
						this.scroll_sign_2 = 1
					}
					if(!this.scroll_2_stop){
						if(this.scroll_sign_2==-1){
							obj.scrollLeft = obj.scrollLeft - 1
						}else{
							obj.scrollLeft = obj.scrollLeft + 1
						}
					}
				}
				// let phone_obj = document.getElementsByClassName("phone_show_video_layout_2")[0]
				// if(phone_obj){
				// 	let phone_scroll_width = phone_obj.scrollWidth - phone_obj.offsetWidth
				// 	if(phone_obj.scrollLeft >= phone_scroll_width){
				// 		this.scroll_phone_sign_2 = -1
				// 	}else if(phone_obj.scrollLeft <= 0){
				// 		this.scroll_phone_sign_2 = 1
				// 	}
				// 	if(!this.scroll_phone_2_stop){
				// 		if(this.scroll_phone_sign_2==-1){
				// 			phone_obj.scrollLeft = phone_obj.scrollLeft - 1
				// 		}else{
				// 			phone_obj.scrollLeft = phone_obj.scrollLeft + 1
				// 		}
				// 	}
				// }

			},
			update_scroll_value(){
				let i = parseInt(this.choose_product_detail.id) - 1
				// console.log("*update_scroll_i:"+i)
				let obj = document.getElementsByClassName("product_detail_content_img_list")[i]
				if(obj){
					let scroll_width = obj.scrollWidth - obj.offsetWidth
					if(obj.scrollLeft > scroll_width){
						this.scroll_sign_1 = -1
						// obj.scrollLeft = 0
					}if(obj.scrollLeft <= 0){
						this.scroll_sign_1 = 1
					}
					if(this.scroll_sign_1==-1){
						obj.scrollLeft = obj.scrollLeft - 1
					}else{
						obj.scrollLeft = obj.scrollLeft + 1
					}
				}
				let phone_obj = document.getElementsByClassName("phone_product_detail_content_img_list")[i]
				if(phone_obj){
					let phone_scroll_width = phone_obj.scrollWidth - phone_obj.offsetWidth
					if(phone_obj.scrollLeft > phone_scroll_width){
						this.scroll_phone_sign_1 = -1
						// phone_obj.scrollLeft = 0
					}if(phone_obj.scrollLeft <= 0){
						this.scroll_phone_sign_1 = 1
					}
					if(this.scroll_phone_sign_1==-1){
						phone_obj.scrollLeft = phone_obj.scrollLeft - 1
					}else{
						phone_obj.scrollLeft = phone_obj.scrollLeft + 1
					}
				}
				
			},
			jump_to_consultation(){
				this.$router.push("/main/contact_us")//跳转
				// this.$refs.main_menu.open("contact_us")
				// window.location.href =  window.location.origin + "/main/contact_us"
			},
			choose_one_product(id){
				clearInterval(this.scroll_timer_1);
				// 特殊处理，先隐藏再由通用逻辑显示
				if(this.show_product_list_index_sub=='1_1'){
					this.show_product_detail = false
				}
				// 调整整体框架高度
				// if(id<=4){
				// 	document.getElementsByClassName("product_list_layout")[0].style.height = '1050px'
				// }else{
				// 	document.getElementsByClassName("product_list_layout")[0].style.height = '920px'
				// }
				
				if(!this.show_product_detail){
					this.show_product_detail = true
					this.choose_product_detail.text = ""
					let obj = ""
					let phone_obj = ""
					switch(id){
						case 1:
						this.choose_product_detail.id = 1
						this.choose_product_detail.img = "product_detail_img one_product_img_1"
						this.choose_product_detail.name = "机器人控制器"
						this.choose_product_detail.text = `◆ 掌控核心技术，拥有自主产权；<br/>◆ 算法不输国外行业最先进同类产品；<br/>◆ 实现平面及三维空间任意直线、圆弧的插补，可以分解任意轨迹；<br/>◆ 自主编译语言和界面；<br/>◆ 实现离线编程，三维动态仿真；<br/>◆ 可实现多轴联动，处于行业领先水平；<br/>◆ 控制柜大小可定制，支持Ethercat总线。`				
						obj = document.getElementsByClassName("product_detail_text")[0]
						if(obj){
							obj.innerHTML = this.choose_product_detail.text
						}
						phone_obj = document.getElementsByClassName("phone_product_detail_text")[0]
						if(phone_obj){
							phone_obj.innerHTML = this.choose_product_detail.text
						}
						this.scroll_timer_1 = setInterval(this.update_scroll_value,10)
							break
						case 2:
						this.choose_product_detail.id = 2
						this.choose_product_detail.img = "product_detail_img one_product_img_2"
						this.choose_product_detail.name = "AMR/AGV控制器"
						this.choose_product_detail.text = `◆ 可远程遥控/可自主控制，适应多种结构车体平台化系统架构；<br/>◆ 可定制无人驾驶系统，融合激光雷达、视觉IMU多种传感器；<br/>◆ 实现特定路径自主行驶，以及地图构建能力；<br/>◆ 自主开发软硬件系统，模块化设计。`	
						obj = document.getElementsByClassName("product_detail_text")[1]
						if(obj){
							obj.innerHTML = this.choose_product_detail.text
						}
						phone_obj = document.getElementsByClassName("phone_product_detail_text")[1]
						if(phone_obj){
							phone_obj.innerHTML = this.choose_product_detail.text
						}
						this.scroll_timer_1 = setInterval(this.update_scroll_value,10)
							break
						case 3:
						this.choose_product_detail.id = 3
						this.choose_product_detail.img = "product_detail_img one_product_img_4"
						this.choose_product_detail.name = "PLC控制器"
						this.choose_product_detail.text = `◆ 支持梯形图编程方式；<br/>◆ 支持远程通讯监测；<br/>◆ 支持全隔离设置；<br/>◆ 支持各种数字/模拟量的输入/输出，集成常用的工业现场总线；<br/>◆ 可按客户要求内嵌实时内核系统；<br/>◆ 国产化替代。`
						obj = document.getElementsByClassName("product_detail_text")[2]
						if(obj){
							obj.innerHTML = this.choose_product_detail.text
						}
						phone_obj = document.getElementsByClassName("phone_product_detail_text")[2]
						if(phone_obj){
							phone_obj.innerHTML = this.choose_product_detail.text
						}
						this.scroll_timer_1 = setInterval(this.update_scroll_value,10)
							break
						case 4:
						this.choose_product_detail.id = 4
						this.choose_product_detail.img = "product_detail_img one_product_img_18"
						this.choose_product_detail.name = "视觉控制器"
						this.choose_product_detail.text = `◆ 拖拽式操作，不用编程；<br/>◆ 支持多相机多任务多算法融合，接口丰富；<br/>◆ 移植简单方便，容易嵌入第三方程序；<br/>◆ 可视化、模块化、丰富的算法;<br/>◆ 自主开发，可按客户需求定制;<br/>◆ 专有视觉控制器，设计小巧灵动，具有高稳定性、低成本、反应快速等特点。`
						obj = document.getElementsByClassName("product_detail_text")[3]
						if(obj){
							obj.innerHTML = this.choose_product_detail.text
						}
						phone_obj = document.getElementsByClassName("phone_product_detail_text")[3]
						if(phone_obj){
							phone_obj.innerHTML = this.choose_product_detail.text
						}
						this.scroll_timer_1 = setInterval(this.update_scroll_value,10)
							break
						case 5:
						this.choose_product_detail.id = 5
						this.choose_product_detail.img = "product_detail_img one_product_img_5"
						this.choose_product_detail.name = "机器人离线编程软件"
						this.choose_product_detail.text = `◆ 支持导入多种本体结构和末端执行器，支持外部三维模型导入和放置，便于用户设计出各种机械和几何模型；<br/>◆ 支持在线调试和离线调试，支持离线仿真；<br/>◆ 支持程序编译和调试，支持用户定义编程语言和语法结构；<br/>◆ 支持虚拟控制器，轻松进行机器人编程培训、实际编程和优化等任务；<br/>◆ 支持行业定制程序软件包的加载。`
						obj = document.getElementsByClassName("product_detail_text")[4]
						if(obj){
							obj.innerHTML = this.choose_product_detail.text
						}
						phone_obj = document.getElementsByClassName("phone_product_detail_text")[4]
						if(phone_obj){
							phone_obj.innerHTML = this.choose_product_detail.text
						}
						
							break
						case 6:
						this.choose_product_detail.id = 6
						this.choose_product_detail.img = "product_detail_img one_product_img_6"
						this.choose_product_detail.name = "5G云平台"
						this.choose_product_detail.text = `◆ 采用分布式架构；<br/>◆ 协议兼容，多网互通；<br/>◆ 数据驱动，实现数据共享与协同；<br/>◆ 实现数据平台化、能力中心化、业务服务化，提升运维效率。<br/>`
						obj = document.getElementsByClassName("product_detail_text")[4]
						if(obj){
							obj.innerHTML = this.choose_product_detail.text
						}
						phone_obj = document.getElementsByClassName("phone_product_detail_text")[4]
						if(phone_obj){
							phone_obj.innerHTML = this.choose_product_detail.text
						}						
							break
						case 7:
						this.choose_product_detail.id = 7
						this.choose_product_detail.img = "product_detail_img one_product_img_7"
						this.choose_product_detail.name = "移动小车远程控制软件"
						this.choose_product_detail.text = `◆ 支持雷达与视觉融合建图导航，支持三维重建；<br/>◆ 支持定点导航、多点导航、导航中避障；<br/>◆ 支持多传感器数据显示，直观反馈小车速度、电量等信息；<br/>◆ 支持自动泊车、车道识别、自动驾驶及深度学习功能；<br/>◆ 支持在线远程调参，支持5G网络和多频段无线模块。`
						obj = document.getElementsByClassName("product_detail_text")[4]
						if(obj){
							obj.innerHTML = this.choose_product_detail.text
						}
						phone_obj = document.getElementsByClassName("phone_product_detail_text")[4]
						if(phone_obj){
							phone_obj.innerHTML = this.choose_product_detail.text
						}						
							break
						case 8:
						this.choose_product_detail.id = 8
						this.choose_product_detail.img = "product_detail_img one_product_img_8"
						this.choose_product_detail.name = "工业视觉软件"
						this.choose_product_detail.text = `◆ 支持深度学习模型；<br/>◆ 支持拖拽式操作；<br/>◆ 提供丰富的算法和工具；<br/>◆ 支持多种编程语言和平台；<br/>◆ 具有外观检测、缺陷检测、有无检测、尺寸测量、颜色识别、正反检测、脏点污点检测、视觉引导定位、视觉定位抓取等功能。`
						obj = document.getElementsByClassName("product_detail_text")[4]
						if(obj){
							obj.innerHTML = this.choose_product_detail.text
						}
						phone_obj = document.getElementsByClassName("phone_product_detail_text")[4]
						if(phone_obj){
							phone_obj.innerHTML = this.choose_product_detail.text
						}						
							break
						case 9:
						this.choose_product_detail.id = 9
						this.choose_product_detail.img = "product_detail_img one_product_img_9"
						this.choose_product_detail.name = "AMR/AGV调度软件"
						this.choose_product_detail.text = `◆ 支持多种导航技术，支持混合导航系统；<br/>◆ 支持路径布局、应用开发；<br/>◆ 支持日常维护、故障诊断；<br/>◆ 支持交通调度控制、交通管理、AGV车队管理等；<br/>◆ 采用多种 AI 智能调度算法，实现任务最优分配、多机器人路径规划等。`
						obj = document.getElementsByClassName("product_detail_text")[4]
						if(obj){
							obj.innerHTML = this.choose_product_detail.text
						}
						phone_obj = document.getElementsByClassName("phone_product_detail_text")[4]
						if(phone_obj){
							phone_obj.innerHTML = this.choose_product_detail.text
						}						
							break
						case 10:
						this.choose_product_detail.id = 10
						this.choose_product_detail.img = "product_detail_img one_product_img_10"
						this.choose_product_detail.name = "数字孪生可视化平台"
						this.choose_product_detail.text = `◆ 实现二维平面及3D模型，可根据用户需求进行定制；<br/>◆ 自定义界面，全场景建模；<br/>◆ 数据可视化；<br/>◆ 自动数据统计分析；<br/>◆ 支持多种数据源类型接入。`
						obj = document.getElementsByClassName("product_detail_text")[4]
						if(obj){
							obj.innerHTML = this.choose_product_detail.text
						}
						phone_obj = document.getElementsByClassName("phone_product_detail_text")[4]
						if(phone_obj){
							phone_obj.innerHTML = this.choose_product_detail.text
						}						
							break		
						case 11:
						this.choose_product_detail.id = 11
						this.choose_product_detail.img = "product_detail_img one_product_img_11"
						this.choose_product_detail.name = "工业机器人"
						this.choose_product_detail.text = `◆ 1；<br/>◆2<br/>`
							break	
						case 12:
						this.choose_product_detail.id = 12
						this.choose_product_detail.img = "product_detail_img one_product_img_12"
						this.choose_product_detail.name = "协作机器人"
						this.choose_product_detail.text = `◆ 1；<br/>◆2<br/>`
							break	
						case 13:
						this.choose_product_detail.id = 13
						this.choose_product_detail.img = "product_detail_img one_product_img_13"
						this.choose_product_detail.name = "双臂机器人"
						this.choose_product_detail.text = `◆ 1；<br/>◆2<br/>`
							break	
						case 14:
						this.choose_product_detail.id = 14
						this.choose_product_detail.img = "product_detail_img one_product_img_14"
						this.choose_product_detail.name = "移动机器人"
						this.choose_product_detail.text = `◆ 1；<br/>◆2<br/>`
							break	
						case 15:
						this.choose_product_detail.id = 15
						this.choose_product_detail.img = "product_detail_img one_product_img_15"
						this.choose_product_detail.name = "三轴平台"
						this.choose_product_detail.text = `◆ 1；<br/>◆2<br/>`
							break	
							
					}
				}else{
					this.show_product_detail = false
				}
			},
			change_show_product_list_index(i){
				// document.getElementsByClassName("product_list_layout")[0].style.height = '920px'
				this.show_product_detail = false
				this.show_product_list_index = i
				if(i=='1'){
					// this.show_product_list_index_sub = '1_1'
					document.getElementsByClassName("product_list_sub_layout")[0].scrollTop=0
				}else{
					// this.show_product_list_index_sub = '2_1'
					document.getElementsByClassName("product_list_sub_layout")[1].scrollTop=0
				}
				this.show_product_list_index_sub = '-1'
				if(document.documentElement.clientWidth<1400){
					document.getElementsByClassName("mainlayout_router_view")[0].scrollTop = 280
				}
			},
			change_show_product_list_sub_index(i){
				if(document.documentElement.clientWidth<1400){
					document.getElementsByClassName("mainlayout_router_view")[0].scrollTop = 280
				}				
				this.show_product_list_index = -1
				this.show_product_detail = false
				this.show_product_list_index_sub = i
				if(i=='1_1'){
					this.choose_one_product(1)
				}else if(i=='1_2'){
					this.choose_one_product(2)
				}else if(i=='1_3'){
					this.choose_one_product(3)
				}else if(i=='1_4'){
					this.choose_one_product(4)
				}else if(i=='2_1'){
					this.choose_one_product(5)
				}else if(i=='2_2'){
					this.choose_one_product(7)
				}else if(i=='2_3'){
					this.choose_one_product(9)
				}else if(i=='2_4'){
					this.choose_one_product(10)
				}else if(i=='2_5'){
					this.choose_one_product(6)
				}else if(i=='2_6'){
					this.choose_one_product(8)
				}
			}
		},
		beforeDestroy() {
			clearInterval(this.scroll_timer_1);
			clearInterval(this.scroll_timer_2);
			if(this.player_1){
				this.player_1.dispose()
			}
			if(this.player_2){
				this.player_2.dispose()
			}
			if(this.player_3){
				this.player_3.dispose()
			}
			if(this.player_4){
				this.player_4.dispose()
			}
			if(this.player_5){
				this.player_5.dispose()
			}
			if(this.player_6){
				this.player_6.dispose()
			}
			if(this.player_7){
				this.player_7.dispose()
			}
			if(this.player_8){
				this.player_8.dispose()
			}
			if(this.player_9){
				this.player_9.dispose()
			}
		},
		// 切换界面时调用
		beforeUnmount(){
			clearInterval(this.scroll_timer_1);
			clearInterval(this.scroll_timer_2);
			console.log(this.player_1)
			if(this.player_1){
				this.player_1.dispose()
			}
			if(this.player_2){
				this.player_2.dispose()
			}
			if(this.player_3){
				this.player_3.dispose()
			}
			if(this.player_4){
				this.player_4.dispose()
			}
			if(this.player_5){
				this.player_5.dispose()
			}
			if(this.player_6){
				this.player_6.dispose()
			}
			if(this.player_7){
				this.player_7.dispose()
			}
			if(this.player_8){
				this.player_8.dispose()
			}
			if(this.player_9){
				this.player_9.dispose()
			}
		}
	}
</script>

<style>
	.vjs-paused .vjs-big-play-button,
	.vjs-paused.vjs-has-started .vjs-big-play-button {
	  display: block !important;
	}
	.video-js .vjs-time-control {
	  display: block !important;
	}
	.video-js .vjs-remaining-time {
	  display: none !important;
	}


</style>