<template>
<!-- 	<div class="header_logo">
		<div class="header_logo_img">
			
		</div>
	</div> -->
	<div class="top_btn" @click="set_scroll_top">
		<div class="top_img">
			
		</div>
	</div>
	<div class="header">
		<div class="header_layout_1">
			<div class="header_logo" >
				
			</div>
			<div class="header_text">
				飞凌越
				<!-- 武汉飞凌越智能科技有限公司 -->
			</div>
		</div>
		<div class="header_menu_layout">
			<el-menu
			    :default-active="activeIndex"
			    class="header_menu"
			    mode="horizontal"
			    @select="handleSelect"
				@open="handleOpen"
				router
				ref="main_menu"
			  >
			    <el-menu-item index="home">首页</el-menu-item>
				<el-menu-item index="product_introduction">产品介绍</el-menu-item>
				<!-- <el-menu-item index="page5">典型案例</el-menu-item> -->
				<el-menu-item index="news_information">新闻资讯</el-menu-item>
			    <el-menu-item index="about_us">关于我们</el-menu-item>
				<el-menu-item index="contact_us">联系我们</el-menu-item>
			  </el-menu>
		</div>
		<div class="header_phone">
			<div class="header_phone_logo">
				
			</div>
			<div class="header_phone_text">
				{{telephone}}
			</div>
			
		</div>
		<div class="phone_menu_btn" @click="show_phone_menu">
			<div style="width: 100%;height: 100%;position: absolute;text-align: center;color: #ffffff;">
				菜单
			</div>
		</div>
		<div v-show="phone_menu_is_show" class="phone_menu_layout">
			<div :class="activeIndex=='home'?'phone_menu_item phone_menu_item_choose':'phone_menu_item'" @click="choose_phone_menu_item(1)">
				首页
			</div>
			<div :class="activeIndex=='product_introduction'?'phone_menu_item phone_menu_item_choose':'phone_menu_item'" @click="choose_phone_menu_item(2)">
				产品介绍
			</div>
			<div :class="activeIndex=='news_information'?'phone_menu_item phone_menu_item_choose':'phone_menu_item'" @click="choose_phone_menu_item(3)">
				新闻资讯
			</div>
			<div :class="activeIndex=='about_us'?'phone_menu_item phone_menu_item_choose':'phone_menu_item'" @click="choose_phone_menu_item(4)">
				关于我们
			</div>
			<div :class="activeIndex=='contact_us'?'phone_menu_item phone_menu_item_choose':'phone_menu_item'" @click="choose_phone_menu_item(5)" style="border: none;">
				联系我们
			</div>

		</div>
	</div>
</template>

<script>
export default{
	name:"Header",
	mounted() {
		this.telephone = this.$GLOBAL.telephone

		if(window.location.href.indexOf("home")!=-1){
			this.activeIndex="home"
			sessionStorage.setItem("index","home");
		}else if(window.location.href.indexOf("news_information")!=-1){
			this.activeIndex="news_information"
			sessionStorage.setItem("index","news_information");
		}else if(window.location.href.indexOf("product_introduction")!=-1){
			this.activeIndex="product_introduction"
			sessionStorage.setItem("index","product_introduction");
		}else if(window.location.href.indexOf("about_us")!=-1){
			this.activeIndex="about_us"
			sessionStorage.setItem("index","about_us");
		}else if(window.location.href.indexOf("page5")!=-1){
			this.activeIndex="page5"
			sessionStorage.setItem("index","page5");
		}else if(window.location.href.indexOf("contact_us")!=-1){
			this.activeIndex="contact_us"
			sessionStorage.setItem("index","contact_us");
		}
		
	},
	data() 	{
		return {
			activeIndex:"home",
			telephone:"",
			bg_index:1,
			phone_menu_is_show:false,
		}
	},
	watch:{
	    '$route'(to,from){
	      this.activeIndex=to.name
	    }
	  },
	methods:{
		set_scroll_top(){
			document.getElementsByClassName("mainlayout_router_view")[0].scrollTop = 0
		},
		show_phone_menu(){
			if(this.phone_menu_is_show){
				this.phone_menu_is_show = false
			}else{
				this.phone_menu_is_show = true
			}
		},
		choose_phone_menu_item(i){
			this.phone_menu_is_show = false
			switch(i){
				case 1 :
				this.$router.push("/main/home")//跳转
				break
				case 2 :
				this.$router.push("/main/product_introduction")//跳转
				break
				case 3 :
				this.$router.push("/main/news_information")//跳转
				break
				case 4 :
				this.$router.push("/main/about_us")//跳转
				break
				case 5 :
				this.$router.push("/main/contact_us")//跳转
				break				
			}
		},
		change_bg_img(){
			this.bg_index++
			if(this.bg_index==2){
				document.getElementsByClassName("mainlayout_aside_and_main")[0].className = "mainlayout_aside_and_main mainlayout_aside_and_main_2"
			}else if(this.bg_index==3){
				document.getElementsByClassName("mainlayout_aside_and_main")[0].className = "mainlayout_aside_and_main mainlayout_aside_and_main_3"
			}else if(this.bg_index==4){
				document.getElementsByClassName("mainlayout_aside_and_main")[0].className = "mainlayout_aside_and_main mainlayout_aside_and_main_4"
			}else{
				this.bg_index = 1
				document.getElementsByClassName("mainlayout_aside_and_main")[0].className = "mainlayout_aside_and_main"
			}			
		},
		handleSelect(activeIndex){
			// console.log(this.activeIndex = "product_introduction")
		},
		handleOpen(a){
			console.log(a)
		}
	},
}
</script>

<style>
</style>