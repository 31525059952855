<template>
	<div class="phone_page_layout_5">
		<div class="product_list_menu_title" >
			<div style="font-size: 22px;font-weight: 600;line-height: 38px;">
				新闻资讯
			</div>
			<div style="margin-top: -15px;font-size: 15px;line-height: 25px;">
				News Information
			</div>
			<div class="product_list_return_btn" v-show="show_news_detail" @click="choose_one_news(-1)">
				返回
			</div>
		</div>
		<div class="phone_one_case_layout" v-show="show_product_list_index=='1'&&!show_news_detail" style="background-color: rgba(226, 226, 226, 0.9);">

			<div class="phone_one_news_item" v-for="item in news_data_list"  @click="choose_one_news(item)">				
				<div class="phone_one_news_item_title">
					{{item.title}}
				</div>
				<div class="phone_one_news_item_time">
					{{item.time}}
				</div>
			</div>
			<div style="width: 100%;height: 50px;float: left;"></div>
		</div>
		<div class="phone_one_case_layout" v-show="show_news_detail" style="background-color: rgba(226, 226, 226, 0.9);height: auto;padding-bottom: 25px;">
			<div class="news_detail_title">
				{{now_choose_news_data.title}}
			</div>
			<div class="news_detail_time">
				时间：{{now_choose_news_data.time}}
			</div>
			<div class="news_detail_content" v-for="content_list in now_choose_news_data.content" style="text-indent: 40px;">
				{{content_list}}
			</div>
		</div>
	</div>
	
	
	<div class="page_layout_5">
		<div class="product_list_layout" style="height: 700px;" >
			<div class="product_list_menu">
				<div class="product_list_menu_title" >
					<div style="font-size: 28px;font-weight: 600;line-height: 55px;">
						新闻资讯
					</div>
					<div style="margin-top: -15px;font-size: 16px;line-height: 25px;">
						News Information
					</div>
				</div>

				<div :class="show_product_list_index=='1'?'product_list_item choose_product_list_item':'product_list_item'" @click="change_show_product_list_index(1)">
					<div :class="show_product_list_index=='1'?'product_list_item_icon_choose':'product_list_item_icon'" style="width: 40px;height: 40px;"></div>
					<div class="product_list_item_text" style="padding-left: 10px;">新闻资讯</div>
				</div>
								
				<div class="show_phone_layout">
					<div class="show_phone_text">
						{{telephone}}
					</div>
				</div>
			</div>
			<div class="product_list_content" >
				<div class="product_list_menu_title" >
					<div style="font-size: 28px;font-weight: 600;line-height: 55px;">
						新闻资讯
					</div>
					<div style="margin-top: -15px;font-size: 16px;line-height: 25px;">
						News Information
					</div>
					<div class="product_list_return_btn" v-show="show_news_detail" @click="choose_one_news(-1)">
						返回
					</div>
				</div>
				
				<div class="one_case_layout" v-show="show_product_list_index=='1'&&!show_news_detail" style="background-color: rgba(226, 226, 226, 0.9);height: 680px;">

					<div class="one_news_item" v-for="item in news_data_list"  @click="choose_one_news(item)">
						<div class="one_news_item_title">
							{{item.title}}
						</div>
						<div class="one_news_item_time">
							{{item.time}}
						</div>
					</div>
					<div style="width: 100%;height: 50px;float: left;"></div>
				</div>
				<div class="one_case_layout" v-show="show_news_detail" style="background-color: rgba(226, 226, 226, 0.9);height: 680px;">
					<div class="news_detail_title">
						{{now_choose_news_data.title}}
					</div>
					<div class="news_detail_time">
						时间：{{now_choose_news_data.time}}
					</div>
					<div class="news_detail_content" v-for="content_list in now_choose_news_data.content" style="text-indent: 40px;">
						{{content_list}}
					</div>
				</div>

				
				
			</div>
		</div>
	</div>
	<Bottom></Bottom>
</template>

<script>
	import request from "@/utils/request";
	import Bottom from "@/components/bottom"
	export default{
		name:"introduction",
		components:{
			Bottom
		},
		data() {
			return{
				base_url:SERVER_URL,
				show_product_list_index:"1",
				telephone:"",
				show_news_detail:false,
				news_data_list:[],
				now_choose_news_data:{}
				
			}
		},
		mounted(){
			this.telephone = this.$GLOBAL.telephone
			
			document.getElementsByClassName("mainlayout_router_view")[0].scrollTop=0
			// request.get(this.$GLOBAL.flask_web_local_url+'/get_map_data_info').then(res => {
			// 	// console.log(res)
			// })
			this.news_data_list = [
				{
					id:4,
					title:"启航2024：科技赋能，人形机器人行业奇点将至",
					content:[
						"2023年11月2日，工业和信息化部印发《人形机器人创新发展指导意见》，提出到2025年，我国人形机器人创新体系初步建立，“大脑、小脑、肢体”等一批关键技术取得突破，确保核心部组件安全有效供给。整机产品达到国际先进水平，并实现批量生产，在特种、制造、民生服务等场景得到示范应用，探索形成有效的治理机制和手段。",
						"到2027年，人形机器人技术创新能力显著提升，形成安全可靠的产业链供应链体系，构建具有国际竞争力的产业生态，综合实力达到世界先进水平。产业加速实现规模化发展，应用场景更加丰富，相关产品深度融入实体经济，成为重要的经济增长新引擎。",
						"ChatGPT的发布是人类在信息科学领域的一次重大里程碑，是人工智能领域的“氢弹”时刻，而人形机器人则有望成就人工智能领域的“两弹一星”时刻。",
						"人工智能未来非常重要的发展方向是“具身智能”，而通用机器人作为具身智能最理想的载体，是人工智能时代人们所憧憬的一次巨大工业革命。",
						"2024年的崭新篇章已开启，愿行业龙腾虎跃 ，不仅是人形机器人。", 
					],
					time:"2024-02-25"
				},
				{
					id:2,
					title:"工业和信息化部等十七部门联合印发《“机器人+”应用行动实施方案》",
					content:[
						"1月18日，工业和信息化部、教育部、公安部等十七部门近日印发《“机器人+”应用行动实施方案》，提出到2025年，制造业机器人密度较2020年实现翻番，服务机器人、特种机器人行业应用深度和广度显著提升，机器人促进经济社会高质量发展的能力明显增强。",
						"聚焦10大应用重点领域，突破100种以上机器人创新应用技术及解决方案，推广200个以上具有较高技术水平、创新应用模式和显著应用成效的机器人典型应用场景，打造一批“机器人+”应用标杆企业，建设一批应用体验中心和试验验证中心。",
						"推动各行业、各地方结合行业发展阶段和区域发展特色，开展“机器人+”应用创新实践。搭建国际国内交流平台，形成全面推进机器人应用的浓厚氛围。"						
					],
					time:"2024-01-26"
				},
				{
					id:1,
					title:"《2023世界机器人报告》出炉，中国机器人市场稳步向好",
					content:[
						"11月，国际机器人联合会（IFR）发布《2023世界机器人报告》。",
						"报告显示，2022年，全球工业机器人的装机量为553,052台，中国的工业机器人装机量为290,258台，占全球安装量的52%，同比增长了5%。",
						"2022年，全球工业机器人的运营存量为3,903,633台，同比增长12%。中国的运营存量突破150万台大关，这使中国成为第一个也是唯一一个拥有如此庞大机器人运营存量的国家。",
						"2022年，全球制造业中的工业机器人密度为151台（每万名制造业员工所拥有的工业机器人数量），2021年这一数字为141台。中国自2019年进入前10名，并在2021年升至第五名，密度达322台，并在2022年保持了这一排名，密度达392台。"
					],
					time:"2023-11-29"
				},
				{
					id:3,
					title:"2023世界机器人大会｜全球机器人产业进入新一轮变革机遇期",
					content:[
						"8月16日至22日，2023世界机器人大会在北京召开。在凝心聚智—全球开放合作论坛上，工业和信息化部党组成员、副部长辛国斌提出，当前，全球机器人产业进入新一轮变革机遇期，正驱动人类社会加速进入智能时代；机器人产业逐渐逼近变革跃升的临界点，呈现出智能变革更加迅猛、行业应用更加多样、产业生态更加融合的特征。",
						"技术层面，今年以来，AIGC爆火促进了机器人的智能化，大模型技术不仅增强了机器人的认知智能，还将提升机器人交互和推理能力，推动机器人大脑发育以及智能自主进化。从产业应用角度看，近年来工业机器人覆盖面不断拓展，物流、酒店服务、医疗、商用清洁等领域机器人销量快速增长，人类生产和生活方式不断智能化升级。",
						"值得强调的是，据辛国斌介绍，中国已成为全球机器人产业发展的中坚力量，2022年中国机器人全行业营业收入超过1700亿元，工业机器人销量占全球一半以上，连续10年居世界首位。",
						"2022年，全球制造业中的工业机器人密度为151台（每万名制造业员工所拥有的工业机器人数量），2021年这一数字为141台。中国自2019年进入前10名，并在2021年升至第五名，密度达322台，并在2022年保持了这一排名，密度达392台。"
					],
					time:"2023-08-19"
				},				
			]
		},
		methods:{
			change_show_product_list_index(i){
				this.show_news_detail = false
				this.show_product_list_index = i
			},
			choose_one_news(i){
				if(i==-1){
					this.show_news_detail = false
				}else{
					this.show_news_detail = true
					this.now_choose_news_data = i
					// document.getElementsByClassName("news_detail_content")[0].innerHTML = this.news_data_list[i.id-1].content
				}
			}
		}
	}
</script>

<style>
</style>