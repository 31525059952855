<template>
</template>

<script>
	import request from "@/utils/request";
	import { ElMessage } from 'element-plus'
	// tab页签索引
	let device_tabs_index = "0";
	// 后端服务器地址
	// let flask_web_url = "http://192.168.1.77:7770/device";
	let flask_web_url = SERVER_URL+"/device";
	// let flask_web_url = "http://120.26.82.44:7770/device";
	let flask_web_local_url = flask_web_url;
	// flask_web_local_url = flask_web_url
	
	let telephone = 13986192369
	export default {
		device_tabs_index,
		flask_web_url,
		flask_web_local_url,
		telephone,

		methods:{
			// 判断用户权限
			check_permission(val){
				return new Promise((resolve, reject) => {
					//当第三方api提供的是异步方法
					// resolve();
					let user_data = {
						user:sessionStorage.getItem("user"),
						power:sessionStorage.getItem("power"),
						action:val
					}
					request.post(flask_web_url+'/check_permission',user_data).then(res =>{
						if(res=="500"){
							ElMessage.error('用户数据异常,请重新登录！！！')
							sessionStorage.removeItem("user");
							sessionStorage.clear()
							resolve("500");
						}
						else{
							// ElMessage.success('校验成功')
							resolve(res);
						}
					}).catch(function(res){
						console.error(res)
						ElMessage.error('服务器异常 ！！！')
						resolve("404");
					})
				})				
			},
		},
	}
</script>

<style>
</style>