<template>
	<div class="page_layout_1">
		<el-carousel 
			class="show_img_carousel"
			>
		    
			<el-carousel-item >
				<div class="show_img_carousel_item show_img_1" >
					
				</div>
			</el-carousel-item>
			
			<el-carousel-item >
				<div class="show_img_carousel_item show_img_2" >
					
				</div>
			</el-carousel-item>
						
			<el-carousel-item >
				<div class="show_img_carousel_item show_img_3" >
					
				</div>
			</el-carousel-item>
			
<!-- 			<el-carousel-item >
				<div class="show_img_carousel_item show_img_4" >
					
				</div>
			</el-carousel-item> -->
			
<!-- 			<el-carousel-item >
				<div class="show_img_carousel_item show_img_7" >
					
				</div>
			</el-carousel-item> -->
		</el-carousel>
		<div class="introduction_text" style="margin-top: -60px;">
			<div class="introduction_text_border">
				
			</div>
			<div class="introduction_text_value">
				专注于智能控制与智能制造技术研发、生产、为用户提供自动化应用解决方案
			</div>
			
		</div>
		<div class="about_me_text_tree_layout">
			<div class="about_me_text_tree_line"></div>
			<div class="about_me_text_item" style="height: 250px;margin-top: -15px;">
				
				<div class="tree_item_layout" style="width: 620px;height: 270px;background-color: rgba(255, 255, 255, 0.7);position: absolute;margin-left: -20px;border-radius: 20px;margin-top: -20px;"></div>
				<div style="font-size: 30px;position: absolute;font-weight: 600;">
					关于飞凌越
					<div style="width: 180px;height: 4px;background-color: aqua;"></div>
				</div>
				<div class="tree_connect_line" style="margin-left: 603px;margin-top: 92px;"></div>
				<div style="width: 580px;position: absolute;margin-top: 55px;font-size: 17px;text-align: left;line-height: 26px;text-indent: 35px;">
					<span style="font-weight: 600;">武汉飞凌越智能科技有限公司</span> 是一家以机器人核心技术为依托，涉及机器人控制系统、机器视觉及移动机器人，集研发、生产、系统集成、服务于一体，专注于智能控制与智能制造技术研发、生产、为用户提供自动化应用解决方案的新技术企业。公司具有完全自主知识产权的机器人运动控制器，在多项技术领域上达到国内外先进水平，在硬件电路、核心算法、离线仿真、人机交互等关键技术上形成完全独创性的技术体系。
					
				</div>
			</div>
			<div class="about_me_text_item">
				<div class="tree_item_layout" style="width: 620px;height: 270px;background-color: rgba(255, 255, 255, 0.7);position: absolute;margin-top: -20px;margin-left: -20px;border-radius: 20px;margin-left: 680px;"></div>
				<div style="font-size: 30px;position: absolute;font-weight: 600;margin-left: 700px;">
					主要业务
					<div style="width: 180px;height: 4px;background-color: aqua;"></div>
				</div>
				<div class="tree_connect_line" style="margin-left: 653px;margin-top: 92px;"></div>
				<div style="width: 580px;position: absolute;margin-top: 55px;font-size: 17px;text-align: left;margin-left: 700px;">
					<div style="width: 100%;overflow: auto;">
						<!-- <div style="width: 10px;height: 10px;background-color: #28292e;float: left;margin-top: 6px;border-radius: 10px;margin-left: 35px;"></div> -->
						<div style="float: left;margin-left: 5px;width: calc(100% - 15px);line-height: 26px;text-indent: 35px;">◆飞凌越主要涉及机器人与自动化装备、工业自动化系统与生产线的制造集成等多个领域；</div>
					</div>
					<div style="width: 100%;overflow: auto;margin-top: 5px;">
						<!-- <div style="width: 10px;height: 10px;background-color: #28292e;float: left;margin-top: 6px;border-radius: 10px;"></div> -->
						<div style="float: left;margin-left: 5px;width: calc(100% - 15px);line-height: 26px;text-indent: 35px;">◆致力于机器人运动控制技术设计、开发及行业应用，提供工业机器人、协作机器人、特种机器人完整导入方案；</div>
					</div>
					<div style="width: 100%;overflow: auto;margin-top: 5px;">
						<!-- <div style="width: 10px;height: 10px;background-color: #28292e;float: left;margin-top: 6px;border-radius: 10px;"></div> -->
						<div style="float: left;margin-left: 5px;width: calc(100% - 15px);line-height: 26px;text-indent: 35px;">◆致力于顶尖的机器视觉算法开发和机器视觉应用方案供应；</div>
					</div>
					<div style="width: 100%;overflow: auto;margin-top: 5px;">
						<!-- <div style="width: 10px;height: 10px;background-color: #28292e;float: left;margin-top: 6px;border-radius: 10px;"></div> -->
						<div style="float: left;margin-left: 5px;width: calc(100% - 15px);line-height: 26px;text-indent: 35px;">◆致力于无人导航算法和移动机器人设计、研发及制造。</div>
					</div>
					
<!-- 					<span style="font-weight: 600;">飞凌越</span>主要涉及工业机器人与自动化装备、工业自动化系统与生产线的制造集成等多个领域。<br />公司具有完全自主知识产权的机器人运动控制器，在多项技术领域上达到国内外先进水平，在硬件电路、核心算法、离线仿真、人机交互等关键技术上形成完全独创性的技术体系，产品涉及控制终端、多关节机械臂及衍生产品。 -->
					
				</div>
			</div>
			
			<div class="about_me_text_item" style="height: 210px;">
				<div class="tree_item_layout"  style="width: 620px;height: 180px;background-color: rgba(255, 255, 255, 0.7);position: absolute;margin-top: -20px;margin-left: -20px;border-radius: 20px;"></div>
				<div style="font-size: 30px;position: absolute;font-weight: 600;">
					综合优势
					<div style="width: 180px;height: 4px;background-color: aqua;"></div>
				</div>
				<div class="tree_connect_line" style="margin-left: 603px;margin-top: 92px;"></div>
				<div style="width: 580px;position: absolute;margin-top: 55px;font-size: 17px;text-align: left;">
					<div style="width: 100%;height: 30px;">
						<!-- <div style="width: 10px;height: 10px;background-color: #28292e;float: left;margin-top: 4px;border-radius: 10px;"></div> -->
						<div style="float: left;margin-left: 5px;text-indent: 35px;">◆自主原创，掌握核心；</div>
					</div>
					
					<div style="width: 100%;height: 30px;">
						<!-- <div style="width: 10px;height: 10px;background-color: #28292e;float: left;margin-top: 4px;border-radius: 10px;"></div> -->
						<div style="float: left;margin-left: 5px;text-indent: 35px;">◆解决客户个异化技术需求与难点；</div>
					</div>
					
					<div style="width: 100%;height: 30px;">
						<!-- <div style="width: 10px;height: 10px;background-color: #28292e;float: left;margin-top: 4px;border-radius: 10px;"></div> -->
						<div style="float: left;margin-left: 5px;width: calc(100% - 20px);text-indent: 35px;">◆深度融合机器视觉技术、无人导航技术和机器人控制技术。</div>
					</div>
					
				</div>
			</div>
			
			<div class="about_me_text_item" style="height: 780px;">
				<div class="tree_item_layout"  style="width: 620px;height: 810px;background-color: rgba(255, 255, 255, 0.7);position: absolute;margin-top: -60px;margin-left: -20px;border-radius: 20px;margin-top: -20px;margin-left: 680px;"></div>
				<div style="font-size: 30px;position: absolute;font-weight: 600;margin-left: 700px;">
					技术路线
					<div style="width: 180px;height: 4px;background-color: aqua;"></div>
				</div>
				<div class="tree_connect_line" style="margin-left: 653px;margin-top: 92px;"></div>
				<div class="technology_roadmap" style="width: 580px;position: absolute;margin-top: 65px;font-size: 20px;text-align: left;margin-left: 700px;">
					
					
				</div>
			</div>
			
			<div class="about_me_text_item" style="height: 180px;margin-top: -580px;"> 
				<div class="tree_item_layout" style="width: 620px;height: 115px;background-color: rgba(255, 255, 255, 0.7);position: absolute;margin-top: 30px;margin-left: -20px;border-radius: 20px;"></div>
				<div style="font-size: 30px;position: absolute;font-weight: 600;margin-top: 50px;">
					核心价值
					<div style="width: 180px;height: 4px;background-color: aqua;"></div>
				</div>
				<div class="tree_connect_line" style="margin-left: 603px;margin-top: 92px;"></div>
				<div style="width: 580px;position: absolute;margin-top: 105px;font-size: 17px;text-align: left;text-indent: 35px;">
					服务客户，诚实守信，永久创新。
					
				</div>
			</div>
			
			<div class="about_me_text_item" style="height: 200px;">
				<div class="tree_item_layout" style="width: 620px;height: 110px;background-color: rgba(255, 255, 255, 0.7);position: absolute;margin-left: -20px;border-radius: 20px;margin-top: 30px;"></div>
				<div style="font-size: 30px;position: absolute;font-weight: 600;margin-top: 45px;">
					企业使命
					<div style="width: 180px;height: 4px;background-color: aqua;"></div>
				</div>
				<div class="tree_connect_line" style="margin-left: 603px;margin-top: 92px;"></div>
				<div style="width: 580px;position: absolute;margin-top: 100px;font-size: 17px;text-align: left;text-indent: 35px;">
					坚持质量取胜，技术取胜，为用户提供最优服务。
					
				</div>
			</div>
			
			<div class="about_me_text_item" style="height: 130px;">
				<div class="tree_item_layout" style="width: 620px;height: 140px;background-color: rgba(255, 255, 255, 0.7);position: absolute;margin-top: 10px;margin-left: -20px;border-radius: 20px;"></div>
				<div style="font-size: 30px;position: absolute;font-weight: 600;margin-top: 30px;">
					企业愿景
					<div style="width: 180px;height: 4px;background-color: aqua;"></div>
				</div>
				<div class="tree_connect_line" style="margin-left: 603px;margin-top: 92px;"></div>
				<div style="width: 580px;position: absolute;margin-top: 85px;font-size: 17px;text-align: left;text-indent: 35px;">
					为客户真正解决问题，为客户创造持久的价值！专注于智能制造与智能设计，成为受人尊敬的行业标杆！
					
				</div>
			</div>
			
			<div class="about_me_text_item" style="height: 680px;">
				<div class="tree_item_layout" style="width: 620px;height: 500px;background-color: rgba(255, 255, 255, 0.7);position: absolute;margin-left: -20px;border-radius: 20px;margin-top: 30px;margin-left: 680px;"></div>
				<div style="font-size: 30px;position: absolute;font-weight: 600;margin-left: 700px;margin-top: 45px;">
					合作伙伴
					<div style="width: 180px;height: 4px;background-color: aqua;"></div>
				</div>
				<div class="tree_connect_line" style="margin-left: 653px;margin-top: 92px;"></div>
				<div class="logo_2" style="width: 600px;position: absolute;margin-top: 105px;font-size: 17px;text-align: left;margin-left: 700px;height: 450px;">
					<!-- 各大知名院校及港口。 -->
					
				</div>
			</div>
			
		</div>
		
		<div class="phone_about_us_layout">
			<div class="about_us_content_1">
				<div class="about_us_content_title">
					关于飞凌越
				</div>
				<div class="about_us_content_text">
					<span style="font-weight: 700;">武汉飞凌越智能科技有限公司</span> 是一家以机器人核心技术为依托，涉及机器人控制系统、机器视觉及移动机器人，集研发、生产、系统集成、服务于一体，专注于智能控制与智能制造技术研发、生产、为用户提供自动化应用解决方案的新技术企业。公司具有完全自主知识产权的机器人运动控制器，在多项技术领域上达到国内外先进水平，在硬件电路、核心算法、离线仿真、人机交互等关键技术上形成完全独创性的技术体系。
				</div>
				<div class="about_us_content_bottom"></div>
			</div>
			
			<div class="about_us_content_1">
				<div class="about_us_content_title">
					主要业务
				</div>
				<div class="about_us_content_text">
					<div style="width: 100%;overflow: auto;">
						<div style="float: left;margin-left: 5px;width: calc(100% - 15px);line-height: 26px;text-indent: 35px;">◆飞凌越主要涉及机器人与自动化装备、工业自动化系统与生产线的制造集成等多个领域；</div>
					</div>
					<div style="width: 100%;overflow: auto;margin-top: 5px;">
						<div style="float: left;margin-left: 5px;width: calc(100% - 15px);line-height: 26px;text-indent: 35px;">◆致力于机器人运动控制技术设计、开发及行业应用，提供工业机器人、协作机器人、特种机器人完整导入方案；</div>
					</div>
					<div style="width: 100%;overflow: auto;margin-top: 5px;">
						<div style="float: left;margin-left: 5px;width: calc(100% - 15px);line-height: 26px;text-indent: 35px;">◆致力于顶尖的机器视觉算法开发和机器视觉应用方案供应；</div>
					</div>
					<div style="width: 100%;overflow: auto;margin-top: 5px;">
						<div style="float: left;margin-left: 5px;width: calc(100% - 15px);line-height: 26px;text-indent: 35px;">◆致力于无人导航算法和移动机器人设计、研发及制造。</div>
					</div>
				</div>
				<div class="about_us_content_bottom"></div>
			</div>
			
			<div class="about_us_content_1">
				<div class="about_us_content_title">
					综合优势
				</div>
				<div class="about_us_content_text">
					<div style="width: 100%;height: 30px;">
						<div style="float: left;margin-left: 5px;text-indent: 35px;">◆自主原创，掌握核心；</div>
					</div>
					
					<div style="width: 100%;height: 30px;">
						<div style="float: left;margin-left: 5px;text-indent: 35px;">◆解决客户个异化技术需求与难点；</div>
					</div>
					
					<div style="width: 100%;height: 30px;">
						<div style="float: left;margin-left: 5px;width: calc(100% - 20px);text-indent: 35px;">◆深度融合机器视觉技术、无人导航技术和机器人控制技术。</div>
					</div>
				</div>
				<div class="about_us_content_bottom"></div>
			</div>
			
			<div class="about_us_content_1">
				<div class="about_us_content_title">
					技术路线
				</div>
				<div class="about_us_content_text">
					<!-- height: calc((100vw - 50px)*1.2); height: 400px; -->
					<div class="technology_roadmap" style="width: 100%;height: calc((100vw - 50px)*1.2);">
					</div>
				</div>
				<div class="about_us_content_bottom"></div>
			</div>
			
			<div class="about_us_content_1">
				<div class="about_us_content_title">
					核心价值
				</div>
				<div class="about_us_content_text">
					服务客户，诚实守信，永久创新。
				</div>
				<div class="about_us_content_bottom"></div>
			</div>
			
			<div class="about_us_content_1">
				<div class="about_us_content_title">
					企业使命
				</div>
				<div class="about_us_content_text">
					坚持质量取胜，技术取胜，为用户提供最优服务。
				</div>
				<div class="about_us_content_bottom"></div>
			</div>
			
			<div class="about_us_content_1">
				<div class="about_us_content_title">
					企业愿景
				</div>
				<div class="about_us_content_text">
					为客户真正解决问题，为客户创造持久的价值！专注于智能制造与智能设计，成为受人尊敬的行业标杆！
				</div>
				<div class="about_us_content_bottom"></div>
			</div>
			
<!-- 			<div class="about_us_content_1" style="height: 420px;overflow-y: hidden;overflow-x: auto;">
				<div class="about_us_content_title">
					合作伙伴
				</div>
				<div class="about_us_content_text" style="overflow-y: hidden;overflow-x: auto;">
					<div class="logo_2" style="width: 445px;height: 380px;margin-left: -30px;"></div>
				</div>
				<div class="about_us_content_bottom"></div>
			</div> -->
			<div class="about_us_content_1" style="height: auto;overflow-y: hidden;overflow-x: hidden;">
				<div class="about_us_content_title">
					合作伙伴
				</div>
				<div class="about_us_content_text" style="overflow-y: hidden;overflow-x: hidden;width: 100%;margin-left: 0px;">
					<div class="logo_2" style="width: calc(100% + 0px);height: calc((100vw - 40px + 0px)/1.14);margin-left: -0px;"></div>
				</div>
				<div class="about_us_content_bottom"></div>
			</div>

		</div>
		

<!-- 		<div class="show_video_layout_2" v-if="load_video"  style="width: 1px;height: 1px;overflow: hidden;">
					<div style="width: 1863px;height: 324px;overflow: hidden;">

						<video src="../assets/video3_20240412_11361392.mp4" muted autoplay loop preload="auto" width="575px" height="324px" style="float: left;">
							3
						</video>

						<video src="../assets/video4_20240412_11400562.mp4" muted autoplay loop preload="auto" width="184px" height="324px" style="float: left;">
							4
						</video>
						
						<video src="../assets/video1_20240412_11382240.mp4" muted autoplay  loop preload="auto" width="184px" height="324px" style="float: left;">
							1
						</video>
						<video src="../assets/video5_20240412_11410751.mp4" muted autoplay loop preload="auto" width="184px" height="324px" style="float: left;">
							5
						</video>
						
						<video src="../assets/video6_20240410_11313988.mp4" muted autoplay loop preload="auto" width="184px" height="324px" style="float: left;">
							6
						</video>
						
						<video src="../assets/video7_20240412_11580387.mp4" muted autoplay loop preload="auto" width="184px" height="324px" style="float: left;">
							7
						</video>
						<video src="../assets/video8_20240412_12002352.mp4" muted autoplay loop preload="auto" width="184px" height="324px" style="float: left;">
							8
						</video>						
						<video src="../assets/video9__20240412_12013285.mp4" muted autoplay loop preload="auto" width="184px" height="324px" style="float: left;">
							9
						</video>
					</div>
		</div> -->
		
	</div>
	<Bottom></Bottom>
	
</template>

<script>
	import request from "@/utils/request";
	import Bottom from "@/components/bottom"
	
	export default{
		name:"page1",
		components:{
			Bottom
		},
		data() {
			return{
				base_url:SERVER_URL,
				load_video:false
			}
		},
		mounted(){
			document.getElementsByClassName("mainlayout_router_view")[0].scrollTop=0
				let that = this
				// setTimeout(function(){
				// 	that.load_video = true
				// },1200)
			 
			// request.get(this.$GLOBAL.flask_web_local_url+'/get_map_data_info').then(res => {
			// 	// console.log(res)
			// })
		}
	}
</script>

<style>
</style>