<template>
	<div class="phone_page_layout_5">
		<div class="product_list_menu_title" >
			<div style="font-size: 22px;font-weight: 600;line-height: 38px;">
				在线留言
			</div>
			<div style="margin-top: -15px;font-size: 15px;line-height: 25px;">
				Online Messag
			</div>
		</div>
		<div class="phone_one_case_layout" style="background-color: rgba(226, 226, 226, 0.9);height: auto;padding-bottom: 25px;">
<el-input v-model="customer_name"
						placeholder="您的称呼" 
						clearable
						class="customer_name_input"
					>
						<template #prefix>
						    <div class="customer_name_input_icon"></div>
					    </template>
					</el-input>
					
					<el-input v-model="customer_phone"
						placeholder="您的电话" 
						clearable
						class="customer_phone_input"
					>
						<template #prefix>
						    <div class="customer_phone_input_icon"></div>
					    </template>
					</el-input>
					
					<el-input v-model="customer_email"
						placeholder="您的邮箱" 
						clearable
						class="customer_email_input"
					>
						<template #prefix>
						    <div class="customer_email_input_icon"></div>
					    </template>
					</el-input>
					
					<el-input v-model="customer_message"
						placeholder="请填写您的需求或留言信息..." 
						clearable
						class="customer_message_input"
						type="textarea"
						resize="none"
						:autosize="{ minRows: 3, maxRows: 3 }"
					>
					</el-input>
					<div class="submit_message_btn" @click="submit_message">
						提交
					</div>
					<div class="reset_message_btn" @click="reset_message">
						重置
					</div>
					<div class="feedback_text_layout" v-show="feedback_sign!=0">
						<div v-show="feedback_sign==-1" style="color: #df0000;font-size: 15px;">
							请填写完整，内容不能为空
						</div>
						
						<div v-show="feedback_sign==1" style="color: #00aaff;font-size: 15px;">
							留言信息提交成功！
						</div>
						<div v-show="feedback_sign==-2" style="color: #00aaff;font-size: 15px;">
							当前网络繁忙，请稍后再试...
						</div>
					</div>			
			
		</div>
		<div class="product_list_menu_title" style="float: left;margin-top: 10px;">
			<div style="font-size: 22px;font-weight: 600;line-height: 38px;">
				联系我们
			</div>
			<div style="margin-top: -15px;font-size: 15px;line-height: 25px;">
				Contact Us
			</div>
		</div>
		<div class="phone_one_case_layout" style="background-color: rgba(226, 226, 226, 0.9);height: auto;padding-bottom: 25px;">
			<div style="width: calc(100% - 20px);height: 100%;text-align: left;padding-top: 20px;float: left;margin-left: 10px;">
				<div style="text-indent: 33px;">
					<span style="font-weight: 600;">武汉飞凌越智能科技有限公司</span>
				</div>
				<div style="text-indent: 33px;margin-top: 10px;">
					联系电话：{{telephone}}
				</div>
				<div style="text-indent: 33px;margin-top: 10px;">
					地址：武汉市东湖高新技术开发区光谷芯中心
				</div>
				<div style="text-indent: 33px;margin-top: 10px;">
					邮箱：125469752@qq.com
				</div>
				
			</div>				
			
		</div>
	</div>
	<div class="page_layout_5">
		<div class="product_list_layout" style="height: 700px;" >
			<div class="product_list_menu">
				<div class="product_list_menu_title" >
					<div style="font-size: 28px;font-weight: 600;line-height: 55px;">
						联系我们
					</div>
					<div style="margin-top: -15px;font-size: 16px;line-height: 25px;">
						Contact Us
					</div>
				</div>

				<div :class="show_product_list_index=='1'?'product_list_item choose_product_list_item':'product_list_item'" @click="change_show_product_list_index(1)">
					<div :class="show_product_list_index=='1'?'product_list_item_icon_choose':'product_list_item_icon'" style="width: 40px;height: 40px;"></div>
					<div class="product_list_item_text" style="padding-left: 10px;">
						联系方式
					</div>
				</div>
								
				<div class="show_phone_layout">
					<div class="show_phone_text">
						{{telephone}}
					</div>
				</div>
			</div>
			<div class="product_list_content" >
				
				<div class="product_list_menu_title" >
					<div style="font-size: 28px;font-weight: 600;line-height: 55px;">
						在线留言
					</div>
					<div style="margin-top: -15px;font-size: 16px;line-height: 25px;">
						Online Messag
					</div>
				</div>
				
				<div class="one_case_layout" v-show="show_product_list_index=='1'" style="background-color: rgba(255, 255, 255, 0.7);height: 330px;float: left;text-align: left;">
					
					<el-input v-model="customer_name"
						placeholder="您的称呼" 
						clearable
						class="customer_name_input"
					>
						<template #prefix>
						    <div class="customer_name_input_icon"></div>
					    </template>
					</el-input>
					
					<el-input v-model="customer_phone"
						placeholder="您的电话" 
						clearable
						class="customer_phone_input"
					>
						<template #prefix>
						    <div class="customer_phone_input_icon"></div>
					    </template>
					</el-input>
					
					<el-input v-model="customer_email"
						placeholder="您的邮箱" 
						clearable
						class="customer_email_input"
					>
						<template #prefix>
						    <div class="customer_email_input_icon"></div>
					    </template>
					</el-input>
					
					<el-input v-model="customer_message"
						placeholder="请填写您的需求或留言信息..." 
						clearable
						class="customer_message_input"
						type="textarea"
						resize="none"
						:autosize="{ minRows: 3, maxRows: 3 }"
					>
					</el-input>
					<div class="submit_message_btn" @click="submit_message">
						提交
					</div>
					<div class="reset_message_btn" @click="reset_message">
						重置
					</div>
					<div class="feedback_text_layout" v-show="feedback_sign!=0">
						<div v-show="feedback_sign==-1" style="color: #df0000;">
							请填写完整，内容不能为空
						</div>
						
						<div v-show="feedback_sign==1" style="color: #00aaff;">
							留言信息提交成功！
						</div>
						<div v-show="feedback_sign==-2" style="color: #00aaff;">
							当前网络繁忙，请稍后再试...
						</div>
					</div>
				</div>
				
				<div class="product_list_menu_title" style="margin-top: 20px;float: left;">
					<div style="font-size: 28px;font-weight: 600;line-height: 55px;">
						联系我们
					</div>
					<div style="margin-top: -15px;font-size: 16px;line-height: 25px;">
						Contact Us
					</div>

				</div>
				
				<div class="one_case_layout" v-show="show_product_list_index=='1'" style="background-color: rgba(255, 255, 255, 0.7);height: 280px;">
					<div style="width: 480px;height: 100%;text-align: left;padding-top: 60px;float: left;">
						<div style="text-indent: 40px;">
							<span style="font-weight: 600;">武汉飞凌越智能科技有限公司</span>
						</div>
						<div style="text-indent: 40px;margin-top: 10px;">
							联系电话：{{telephone}}
						</div>
						<div style="text-indent: 40px;margin-top: 10px;">
							地址：武汉市东湖高新技术开发区光谷芯中心
						</div>
						<div style="text-indent: 40px;margin-top: 10px;">
							邮箱：125469752@qq.com 
						</div>
					</div>
<!-- 					<div class="qr_code_img">
						
					</div> -->
					
					<!-- <div style="width: 100%;height: 50px;float: left;"></div> -->
				</div>
				
<!-- 				<div class="product_list_menu_title" style="float: left;margin-top: 20px;" >
					<div style="font-size: 28px;font-weight: 600;line-height: 70px;">
						在线留言
					</div>
					<div style="margin-top: -15px;font-size: 16px;">
						LIST CENTER
					</div>
				
				</div>
				
				<div class="one_case_layout" v-show="show_product_list_index=='1'" style="background-color: rgba(255, 255, 255, 0.7);height: 280px;">
				
				</div> -->
				
				
			</div>
		</div>
	</div>
	<Bottom></Bottom>
</template>

<script>
	import request from "@/utils/request";
	import Bottom from "@/components/bottom"
	export default{
		name:"contact_us",
		components:{
			Bottom
		},
		data() {
			return{
				base_url:SERVER_URL,
				show_product_list_index:"1",
				telephone:"",
				customer_name:"",
				customer_phone:"",
				customer_email:"",
				customer_message:"",
				feedback_sign:0
			}
		},
		mounted(){
			this.telephone = this.$GLOBAL.telephone
			
			document.getElementsByClassName("mainlayout_router_view")[0].scrollTop=0
			// request.get(this.$GLOBAL.flask_web_local_url+'/get_map_data_info').then(res => {
			// 	// console.log(res)
			// })
		},
		methods:{
			submit_message(){
				if(this.customer_name==""||this.customer_phone==""){
					this.feedback_sign = -1
				}else{
					// this.feedback_sign = 1
					let message = {
						user_name:this.customer_name,
						user_phone:this.customer_phone,
						user_email:this.customer_email,
						user_message:this.customer_message
					}
					request.post(this.$GLOBAL.flask_web_local_url+'/submit_one_message',message).then(res => {
						if(res=="200"){
							this.feedback_sign = 1
							// this.customer_name = ""
							// this.customer_phone = ""
							// this.customer_email = ""
							// this.customer_message = ""
						}else{
							this.feedback_sign = -2
						}
					})
				}
			},
			reset_message(){
				this.customer_name = ""
				this.customer_phone = ""
				this.customer_email = ""
				this.customer_message = ""
				this.feedback_sign = 0
			},
			change_show_product_list_index(i){
				this.show_product_detail = false
				this.show_product_list_index = i
			}
		}
	}
</script>

<style>
</style>