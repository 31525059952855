import { createRouter, createWebHistory } from 'vue-router'
import MainLayout from '@/components/MainLayout'
import home from "@/views/page1"
import news_information from "@/views/page2"
import product_introduction from "@/views/page3"
import about_us from "@/views/page4"
import page5 from "@/views/page5"
import contact_us from "@/views/page6"
import login from "@/views/Login"
import loading from "@/views/loading"
const routes = [
  {
  	path: '/',
  	name: '/',
  	// redirect:"/login",
	redirect:"/main",
  },
  {
  	path: '/loading',
  	name: 'loading',
  	component:loading
  },
  {
  	path: '/login',
  	name: 'login',
  	component:login
  },
  {
    path: '/main',
    name: 'MainLayout',
    component: MainLayout,
	redirect:"/main/home",
	children:[
		{
			path:'home',
			name:'home',
			component:home,
		},
		{
			path:'news_information',
			name:'news_information',
			component:news_information,
		},
		{
			path:'product_introduction',
			name:'product_introduction',
			component:product_introduction,
		},
		{
			path:'about_us',
			name:'about_us',
			component:about_us,
		},
		{
			path:'page5',
			name:'page5',
			component:page5,
		},
		{
			path:'contact_us',
			name:'contact_us',
			component:contact_us,
		},
	]
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
