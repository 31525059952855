<template>
	<div class="phone_page_layout_5">
		<div class="product_list_menu_title" >
			<div style="font-size: 22px;font-weight: 600;line-height: 38px;">
				关于我们
			</div>
			<div style="margin-top: -15px;font-size: 15px;line-height: 25px;">
				About Us
			</div>
		</div>
		<div class="phone_one_case_layout" style="background-color: rgba(226, 226, 226, 0.9);height: auto;padding-bottom: 25px;">
			<div style="width: calc(100% - 80px);text-align: left;margin-left: 40px;margin-top: 30px;line-height: 30px;font-size: 20px;color: #000000;text-indent: 40px;">
				<span style="font-weight: 600;">武汉飞凌越智能科技有限公司</span>位于湖北省武汉市东湖高新技术开发区，是一家以机器人核心技术为依托，集研发、生产、系统集成、服务于一体，专注于智能控制与智能制造技术研发、生产、为用户提供自动化应用解决方案的新技术企业，主要涉及工业机器人与自动化装备、机器视觉与移动机器人、工业自动化系统与生产线的制造集成等多个领域。公司具有完全自主知识产权的机器人运动控制器，在多项技术领域上达到国内外先进水平，在硬件电路、核心算法离线仿真、人机交互等关键技术上形成完全独创性的技术体系。公司建有现代化研发中心，拥有一支文化层次高，富有活力与创新精神的青年团队和数名从业几十年、经验丰富的产品开发高级技术工程师，与知名院校进行战略合作，形成研产销合一的产业模式。
			</div>
			<div style="width: calc(100% - 80px);text-align: left;margin-left: 40px;margin-top: 5px;line-height: 30px;font-size: 20px;color: #000000;text-indent: 40px;">
				公司坚持质量取胜，技术取胜的经营理念，一切以客户需求为宗旨，以精良的产品质量和优质的产品服务赢得客户的信赖，力争成为行业最优秀的企业之一!
			</div>					
			
		</div>
	</div>
	<div class="page_layout_5">
		<div class="product_list_layout" style="height: 700px;" >
			<div class="product_list_menu">
				<div class="product_list_menu_title" >
					<div style="font-size: 28px;font-weight: 600;line-height: 55px;">
						关于我们
					</div>
					<div style="margin-top: -15px;font-size: 16px;line-height: 25px;">
						About Us
					</div>
				</div>

				<div :class="show_product_list_index=='1'?'product_list_item choose_product_list_item':'product_list_item'" @click="change_show_product_list_index(1)">
					<div :class="show_product_list_index=='1'?'product_list_item_icon_choose':'product_list_item_icon'" style="width: 40px;height: 40px;"></div>
					<div class="product_list_item_text" style="padding-left: 10px;">公司简介</div>
				</div>
								
				<div class="show_phone_layout">
					<div class="show_phone_text">
						{{telephone}}
					</div>
				</div>
			</div>
			<div class="product_list_content" >
				<div class="product_list_menu_title" >
					<div style="font-size: 28px;font-weight: 600;line-height: 55px;">
						关于我们
					</div>
					<div style="margin-top: -15px;font-size: 16px;line-height: 25px;">
						About Us
					</div>

				</div>
				
				<div class="one_case_layout" v-show="show_product_list_index=='1'" style="background-color: rgba(226, 226, 226, 0.9);height: 380px;">
					<div style="width: calc(100% - 80px);text-align: left;margin-left: 40px;margin-top: 30px;line-height: 30px;font-size: 20px;color: #000000;text-indent: 40px;">
						<span style="font-weight: 600;">武汉飞凌越智能科技有限公司</span>位于湖北省武汉市东湖高新技术开发区，是一家以机器人核心技术为依托，集研发、生产、系统集成、服务于一体，专注于智能控制与智能制造技术研发、生产、为用户提供自动化应用解决方案的新技术企业，主要涉及工业机器人与自动化装备、机器视觉与移动机器人、工业自动化系统与生产线的制造集成等多个领域。公司具有完全自主知识产权的机器人运动控制器，在多项技术领域上达到国内外先进水平，在硬件电路、核心算法离线仿真、人机交互等关键技术上形成完全独创性的技术体系。公司建有现代化研发中心，拥有一支文化层次高，富有活力与创新精神的青年团队和数名从业几十年、经验丰富的产品开发高级技术工程师，与知名院校进行战略合作，形成研产销合一的产业模式。
					</div>
					<div style="width: calc(100% - 80px);text-align: left;margin-left: 40px;margin-top: 5px;line-height: 30px;font-size: 20px;color: #000000;text-indent: 40px;">
						公司坚持质量取胜，技术取胜的经营理念，一切以客户需求为宗旨，以精良的产品质量和优质的产品服务赢得客户的信赖，力争成为行业最优秀的企业之一!
					</div>					

				</div>
				
				<div class="one_case_layout" v-if="false" style="background-color: rgba(255, 255, 255, 0.7);height: 700px;">
					<div class="show_img_layout_4">
						
					</div>
					<div class="show_img_layout_4_text" >
						<span style="font-weight: 600;">武汉飞凌越智能科技有限公司</span>是一家以工业机器人核心技术为依托，集研发、生产、系统集成、服务于一体，专注于智能控制与智能制造技术研发、生产、为用户提供自动化应用解决方案的新技术企业。
					</div>
					
					<div class="show_img_layout_5_text">
						<div style="text-indent: 40px;">
							<span style="font-weight: 600;">飞凌越</span>主要涉及工业机器人与自动化装备、工业自动化系统与生产线的制造集成等多个领域。
						</div>
						<div style="text-indent: 40px;">
							公司具有完全自主知识产权的机器人运动控制器，在多项技术领域上达到国内外先进水平，在硬件电路、核心算法、离线仿真、人机交互等关键技术上形成完全独创性的技术体系，产品涉及控制终端、多关节机械臂及衍生产品；
						</div>
						<!-- 并与知名院校进行战略合作，形成研产销合一的产业模式；	 -->    
						
					</div>
					<div class="show_img_layout_5">
						
					</div>
					
					<div class="show_img_layout_6">
						
					</div>
					<div class="show_img_layout_6_text" >
						<div class="show_img_layout_6_text_item" >
							<div class="show_img_layout_6_text_item_point"></div>
							<div class="show_img_layout_6_text_item_value">自主原创，掌握核心；</div>
						</div>
						<div class="show_img_layout_6_text_item" >
							<div class="show_img_layout_6_text_item_point"></div>
							<div class="show_img_layout_6_text_item_value">解决客户个异化技术需求与难点；</div>
						</div>
						<div class="show_img_layout_6_text_item" >
							<div class="show_img_layout_6_text_item_point"></div>
							<div class="show_img_layout_6_text_item_value">与知名院校进行战略合作，形成研产销合一的产业模式；</div>
						</div>
						<div class="show_img_layout_6_text_item" >
							<div class="show_img_layout_6_text_item_point"></div>
							<div class="show_img_layout_6_text_item_value">深度融合智能图像处理技术、大数据云计算技术和工业机器人控制技术；</div>
						</div>
					</div>
					
					<div class="show_img_layout_7_text">
						公司一角
					</div>
					<div class="show_img_layout_7">
						<el-carousel
							class="show_img_carousel_2"
							>
						    
							<el-carousel-item >
								<div class="show_img_carousel_item show_img_5" >
									
								</div>
							</el-carousel-item>
							
							<el-carousel-item >
								<div class="show_img_carousel_item show_img_6" >
									
								</div>
							</el-carousel-item>
							

						</el-carousel>
					</div>
					
					
					<div style="width: 100%;height: 50px;float: left;"></div>
					
				</div>
			</div>
		</div>
	</div>
	<Bottom></Bottom>
</template>

<script>
	import request from "@/utils/request";
	import Bottom from "@/components/bottom"
	export default{
		name:"page4",
		components:{
			Bottom
		},
		data() {
			return{
				base_url:SERVER_URL,
				show_product_list_index:"1",
				telephone:"",
				
			}
		},
		mounted(){
			this.telephone = this.$GLOBAL.telephone
			document.getElementsByClassName("mainlayout_router_view")[0].scrollTop=0
			// request.get(this.$GLOBAL.flask_web_local_url+'/get_map_data_info').then(res => {
			// 	// console.log(res)
			// })
		},
		methods:{
			change_show_product_list_index(i){
				this.show_product_detail = false
				this.show_product_list_index = i
			}
		}
	}
</script>

<style>
</style>