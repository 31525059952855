<template>
	<div class="page_layout_5">
		<div class="product_list_layout" style="height: 700px;">
			<div class="product_list_menu">
				<div class="product_list_menu_title" >
					<div style="font-size: 28px;font-weight: 600;line-height: 55px;">
						典型案例
					</div>
					<div style="margin-top: -15px;font-size: 16px;line-height: 25px;">
						LIST CENTER
					</div>
				</div>

				<div :class="show_product_list_index=='1'?'product_list_item choose_product_list_item':'product_list_item'" @click="change_show_product_list_index(1)">
					<div :class="show_product_list_index=='1'?'product_list_item_icon_choose':'product_list_item_icon'"></div>
					<div class="product_list_item_text">机器人控制系统</div>
				</div>
				
				<div :class="show_product_list_index=='2'?'product_list_item choose_product_list_item':'product_list_item'" @click="change_show_product_list_index(2)">
					<div :class="show_product_list_index=='2'?'product_list_item_icon_choose':'product_list_item_icon'"></div>
					<div class="product_list_item_text">机器视觉系统</div>
				</div>
				
				<div :class="show_product_list_index=='3'?'product_list_item choose_product_list_item':'product_list_item'" @click="change_show_product_list_index(3)">
					<div :class="show_product_list_index=='3'?'product_list_item_icon_choose':'product_list_item_icon'"></div>
					<div class="product_list_item_text">移动机器人</div>
				</div>
				
				<div :class="show_product_list_index=='4'?'product_list_item choose_product_list_item':'product_list_item'" @click="change_show_product_list_index(4)">
					<div :class="show_product_list_index=='4'?'product_list_item_icon_choose':'product_list_item_icon'"></div>
					<div class="product_list_item_text">移动小车平台</div>
				</div>
				<div class="show_phone_layout">
					<div class="show_phone_text">
						{{telephone}}
					</div>
				</div>
			</div>
			<div class="product_list_content" >
				<div class="product_list_menu_title" >
					<div style="font-size: 28px;font-weight: 600;line-height: 70px;">
						典型案例
					</div>
					<div style="margin-top: -15px;font-size: 16px;">
						LIST CENTER
					</div>
<!-- 					<div class="product_list_return_btn" v-show="show_product_detail" @click="choose_one_product(-1)">
						返回
					</div> -->
				</div>
				
				<div class="one_case_layout" v-show="show_product_list_index=='1'">
					<div class="one_case_img one_case_img_1">
						
					</div>
					<div class="one_case_text">
						机器人控制系统
					</div>
				</div>
				
				<div class="one_case_layout"  v-show="show_product_list_index=='2'">
					<div class="one_case_img one_case_img_2">
						
					</div>
					<div class="one_case_text">
						机器视觉系统
					</div>
				</div>
				
				<div class="one_case_layout"  v-show="show_product_list_index=='3'">
					<div class="one_case_img one_case_img_3">
						
					</div>
					<div class="one_case_text">
						移动机器人
					</div>
				</div>
				
				<div class="one_case_layout"  v-show="show_product_list_index=='4'">
					<div class="one_case_img one_case_img_4">
						
					</div>
					<div class="one_case_text">
						移动小车平台
					</div>
				</div>
				
			</div>
		</div>
	</div>
	<Bottom></Bottom>
</template>

<script>
	import request from "@/utils/request";
	import Bottom from "@/components/bottom"
	export default{
		name:"page5",
		components:{
			Bottom
		},
		data() {
			return{
				base_url:SERVER_URL,
				show_product_list_index:"1",
				telephone:""
			}
		},
		mounted(){
			this.telephone = this.$GLOBAL.telephone
			document.getElementsByClassName("mainlayout_router_view")[0].scrollTop=0
			// request.get(this.$GLOBAL.flask_web_local_url+'/get_map_data_info').then(res => {
			// 	// console.log(res)
			// })
		},
		methods:{
			change_show_product_list_index(i){
				this.show_product_detail = false
				this.show_product_list_index = i
			}
		}
	}
</script>

<style>
</style>