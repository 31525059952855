<template>
	<!-- <Header></Header> -->
	<div style="background-color: cadetblue;width: 100vw;height: 100vh;">
		<div style="width: 100%;font-size: 60px;color: aliceblue;">
			手机版本正在开发中...
		</div>
		<div style="width: 100%;font-size: 50px;color: #00ffff;" >
			请从PC端访问并将浏览器窗口最大化
		</div>
	</div>

<!-- 	<div style="width: 100%;font-size: 60px;color: #00ffff;" @click="return_main_router">
		点击返回主页
	</div> -->
	
</template>

<script>
import Header from '@/components/Header'
export default{
	name:"loading",
	components:{
		Header
	},
	methods:{
		return_main_router(){
			this.$router.push({
				path: '/main/' ,
			})
		}

	}
}
</script>

<style>
</style>