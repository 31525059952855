<template>
	<div class="bottom_layout">
		武汉飞凌越智能科技有限公司, 版权所有 Copyright@2023   All Rights Reserved.				
		<a target="_blank" href="https://beian.miit.gov.cn" >
			鄂ICP备2023006219号-2
		</a>
	</div>
	<div class="phone_bottom_layout" style="margin-top: 20px;">
		武汉飞凌越智能科技有限公司
	</div>
	<div class="phone_bottom_layout">
		版权所有 Copyright@2023   All Rights Reserved.
	</div>
	<div class="phone_bottom_layout">
		<a target="_blank" href="https://beian.miit.gov.cn" >
			鄂ICP备2023006219号-2
		</a>
	</div>
</template>

<script>
</script>

<style>
</style>