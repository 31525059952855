<template>
	<Header></Header>
	<div class="mainlayout_aside_and_main">
		<!-- <Aside></Aside> -->
		<div class="mainlayout_router_view">
			<router-view/>
		</div>
	</div>
	
</template>

<script>
import Header from '@/components/Header'
import Aside from '@/components/Aside'
export default{
	name:"Layout",
	components:{
		Header,
		Aside,
	}
}
</script>

<style>
</style>